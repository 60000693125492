import React from 'react';
import { useMutation } from 'react-apollo';

import Form, { Field } from '@atlaskit/form';
import { Checkbox } from '@atlaskit/checkbox';
import TextField from '@atlaskit/textfield';

import { useSessionData } from '@confluence/session-data';

import type { RunImport } from '../../__types__/RunImportMutation';
import { RunImportMutation } from '../../RunImport.graphql';

interface FormData {
	filestoreId: string;
	baseUrl: string;
	application: string;
	cloudId: string;
	accountId: string;
	integrationToken: string;
	accessToken: string;
	importPageData: boolean;
	importPermissions?: string;
	fullImport: boolean;
	spaceName?: string;
	importUsers: boolean;
	orgId: string;
}
export const RunImportDevOnly: React.FC = () => {
	const { userId: accountId, cloudId, orgId } = useSessionData();
	const [runImport] = useMutation<RunImport>(RunImportMutation);

	const handleSubmit = (data: FormData) => {
		void runImport({
			variables: {
				input: data,
			},
		});
	};
	return (
		<Form<FormData> onSubmit={handleSubmit}>
			{({ formProps }) => (
				<form {...formProps}>
					<h1 data-testid="dev-form-warning">
						MAKE SURE YOUR INPUTS ARE CORRECT BEFORE CLICKING SUBMIT
					</h1>
					<h2>open your Chrome Tools Network Tab before clicking submit</h2>
					<Field name="filestoreId" defaultValue="" label="Filestore ID" isRequired>
						{({ fieldProps }) => <TextField {...fieldProps} placeholder="Filestore ID" />}
					</Field>
					<Field name="baseUrl" defaultValue="" label="Base URL" isRequired>
						{({ fieldProps }) => <TextField {...fieldProps} placeholder="Base URL" />}
					</Field>
					<Field name="application" defaultValue="" label="Application" isRequired>
						{({ fieldProps }) => <TextField {...fieldProps} placeholder="Application" />}
					</Field>
					<Field name="cloudId" defaultValue={cloudId} label="Cloud ID" isRequired>
						{({ fieldProps }) => <TextField {...fieldProps} placeholder="Cloud ID" />}
					</Field>
					<Field name="accountId" defaultValue={accountId || ''} label="Account ID" isRequired>
						{({ fieldProps }) => <TextField {...fieldProps} placeholder="Account ID" />}
					</Field>
					<Field name="integrationToken" defaultValue="" label="Integration Token">
						{({ fieldProps }) => <TextField {...fieldProps} placeholder="Integration Token" />}
					</Field>
					<Field name="accessToken" defaultValue="" label="Access Token">
						{({ fieldProps }) => <TextField {...fieldProps} placeholder="Access Token" />}
					</Field>
					<Field name="importPageData" defaultValue={false} label="Import Page Data">
						{({ fieldProps: { onChange, value, ...restFieldProps } }) => (
							<Checkbox
								{...restFieldProps}
								isChecked={value}
								onChange={(evt) => onChange(evt.currentTarget.checked)}
								label="Import Page Data"
							/>
						)}
					</Field>
					<Field name="importPermissions" defaultValue="" label="Import Permissions" isRequired>
						{({ fieldProps }) => <TextField {...fieldProps} placeholder="Import Permissions" />}
					</Field>
					<Field name="fullImport" defaultValue={false} label="Full Import">
						{({ fieldProps: { onChange, value, ...restFieldProps } }) => (
							<Checkbox
								{...restFieldProps}
								isChecked={value}
								onChange={(evt) => onChange(evt.currentTarget.checked)}
								label="Full Import"
							/>
						)}
					</Field>
					<Field name="spaceName" defaultValue="" label="Space Name">
						{({ fieldProps }) => <TextField {...fieldProps} placeholder="Space Name" />}
					</Field>
					<Field name="importUsers" defaultValue={false} label="Import Users">
						{({ fieldProps: { onChange, value, ...restFieldProps } }) => (
							<Checkbox
								{...restFieldProps}
								isChecked={value}
								onChange={(evt) => onChange(evt.currentTarget.checked)}
								label="Import Users"
							/>
						)}
					</Field>
					<Field name="orgId" defaultValue={orgId} label="Org ID" isRequired>
						{({ fieldProps }) => <TextField {...fieldProps} placeholder="Org ID" />}
					</Field>
					<button type="submit">Submit</button>
				</form>
			)}
		</Form>
	);
};
