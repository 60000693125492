import type { FC } from 'react';
import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import { Box } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { GetGlobalContentIdQuery } from '../../ImportContentMediaToken.graphql';
import type { GetGlobalContentIdQuery as GetGlobalContentIdQueryType } from '../../__types__/GetGlobalContentIdQuery';

import { UploadFileDropzone } from './UploadFileDropzone';

type DropzoneWrapperProps = {
	isNotion?: boolean;
};

export const DropzoneWrapper: FC<DropzoneWrapperProps> = ({ isNotion = false }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { data, error } = useQuery<GetGlobalContentIdQueryType>(GetGlobalContentIdQuery);

	const globalDescriptionId = data?.getGlobalDescription?.id;

	if (error || !globalDescriptionId) {
		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				action: 'errored',
				actionSubject: 'getGlobalDescriptionId',
				source: 'importContentDropzoneWrapper',
				attributes: {
					error,
				},
			},
		}).fire();
		return <Box>Error retrieving Global Content Id: {JSON.stringify(error, null, 2)}</Box>;
	}

	return <UploadFileDropzone contentId={globalDescriptionId} isNotion={isNotion} />;
};
