import React, { useContext, useEffect, useState } from 'react';
import type { FC } from 'react';
import { useIntl, defineMessages } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import { Box, Flex, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { ProgressTracker, type Stages } from '@atlaskit/progress-tracker';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { N30 } from '@atlaskit/theme/colors';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ContentType } from './ImportContentDrawer';
import { ImportContentWorkflowContext } from './ImportContentStore';
import { ImportContentFooter } from './ImportContentFooter';
import { CloseDrawerWarningModal } from './CloseDrawerWarningModal';

const i18n = defineMessages({
	closeDrawer: {
		id: 'import-content.workflow.close-drawer',
		defaultMessage: 'Close Drawer',
		description: 'Text for the button to close the drawer',
	},
});

type WorkflowProps = {
	contentType: ContentType;
	progressTrackerStages: Stages;
	steps: FC<any>[];
	closeDrawer: () => void;
};

export const Workflow: FC<WorkflowProps> = ({
	contentType,
	progressTrackerStages,
	steps,
	closeDrawer,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [stages, setStages] = useState(
		progressTrackerStages.map((stage, index) => ({
			...stage,
			onClick: () => {
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'progressTracker',
						source: 'importContentProgressTracker',
						attributes: {
							index,
							contentType,
						},
					},
				}).fire();
				handlePageChange(index);
			},
		})),
	);
	const [currentIndex, setCurrentIndex] = useState(0);
	const context = useContext(ImportContentWorkflowContext);

	const getStatus = (index: number, newIndex: number) => {
		if (index < newIndex) {
			return 'visited';
		} else if (index === newIndex) {
			return 'current';
		} else {
			return 'unvisited';
		}
	};

	const handlePageChange = (newIndex: number) => {
		setCurrentIndex(newIndex);
		setStages(
			stages.map((stage, index) => ({
				...stage,
				percentageComplete: index < newIndex ? 100 : 0,
				status: getStatus(index, newIndex),
			})),
		);
	};

	// When the workflow is exited, we want to cancel ongoing uploads
	useEffect(() => {
		return () => {
			context.browserCancelFnRef?.current && context.browserCancelFnRef.current();
			context.dropzoneCancelFnRef?.current && context.dropzoneCancelFnRef.current();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const CurrentStepComponent = steps[currentIndex];
	const isNotLastStepOnBothContentTypes =
		(contentType === ContentType.NOTION && currentIndex !== 4) ||
		(contentType === ContentType.HTML && currentIndex !== 2);

	const handleCloseIconClick = () => {
		if (context.isUploadingFile || context.isStartingImport) {
			return context.setShouldRenderCloseDrawerModal(true);
		}
		return closeDrawer();
	};
	const handleCloseDrawer = () => {
		context.setShouldRenderCloseDrawerModal(false);
		return closeDrawer();
	};

	const { formatMessage } = useIntl();
	return (
		<>
			<Stack
				spread="space-between"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
				xcss={xcss({
					width: '100%',
					height: '100%',
				})}
			>
				<Box
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
					xcss={xcss({
						position: 'absolute',
						top: token('space.200'),
						left: token('space.200'),
					})}
					role="header"
				>
					<IconButton
						tabIndex={1}
						autoFocus
						appearance="subtle"
						icon={CrossIcon}
						label={formatMessage(i18n.closeDrawer)}
						onClick={handleCloseIconClick}
					/>
				</Box>
				<Box
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
					xcss={xcss({
						// Needed to offset the top margin that the Drawer component gives us.
						marginTop: 'space.negative.300',
					})}
				>
					<ProgressTracker items={stages} />
				</Box>
				<Flex
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
					xcss={xcss({
						height: '100%',
						width: '100%',
						marginTop: 'space.400',
						paddingTop: 'space.600',
						borderTop: `2px solid ${token('color.border', N30)}`,
						overflow: 'auto',
					})}
					justifyContent="center"
					role="main"
				>
					<CurrentStepComponent handlePageChange={handlePageChange} />
				</Flex>

				{isNotLastStepOnBothContentTypes && (
					<ImportContentFooter
						currentIndex={currentIndex}
						handlePageChange={handlePageChange}
						contentType={contentType}
						closeDrawer={closeDrawer}
					/>
				)}
			</Stack>
			<CloseDrawerWarningModal
				isOpen={context.shouldRenderCloseDrawerModal}
				closeDrawer={handleCloseDrawer}
				closeModal={() => context.setShouldRenderCloseDrawerModal(false)}
			/>
		</>
	);
};
