import React, { useCallback, useContext } from 'react';
import type { FC } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import Link from '@atlaskit/link';
import Button from '@atlaskit/button/new';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { N30 } from '@atlaskit/theme/colors';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ImportContentWorkflowContext } from './ImportContentStore';
import { ContentType } from './ImportContentDrawer';

const i18n = defineMessages({
	cancel: {
		id: 'import-content.footer.cancel',
		defaultMessage: 'Cancel',
		description:
			'Text of the button at the bottom of a step-by-step flow, indicating that clicking it will cancel the flow',
	},
	next: {
		id: 'import-content.footer.next',
		defaultMessage: 'Next',
		description:
			'Text of the button at the bottom of a step-by-step flow, indicating that clicking it will progress the user forwards',
	},
	back: {
		id: 'import-content.footer.back',
		defaultMessage: 'Back',
		description:
			'Text of the button at the bottom of a step-by-step flow, indicating that clicking it will progress the user backwards',
	},
	startImport: {
		id: 'import-content.modal.start-import',
		defaultMessage: 'Start import',
		description:
			'Text of the button at the bottom of a step-by-step flow, indicating that clicking it will begin the import process of their content into Confluence',
	},
	learnMoreLink: {
		id: 'import-content.footer.learn-more-link',
		defaultMessage: 'Learn more about importing from Notion',
		description:
			'Text of a link at the bottom of a step-by-step flow of importing content from Notion to Confluence, that brings the user to a help page with more details about how the process works',
	},
});

type ImportContentFooterProps = {
	currentIndex: number;
	handlePageChange: (newIndex: number) => void;
	contentType: ContentType;
	closeDrawer: () => void;
};

export const ImportContentFooter: FC<ImportContentFooterProps> = ({
	currentIndex,
	handlePageChange,
	contentType,
	closeDrawer,
}) => {
	const context = useContext(ImportContentWorkflowContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isNextButtonEnabled = useCallback(() => {
		if (contentType === ContentType.NOTION) {
			switch (currentIndex) {
				case 0:
					if (context.isUploadingFile || context.filestoreId) {
						return true;
					}
					return false;
				case 1:
					if (!context.shouldMapNotionMembers) {
						return true;
					}
					if (
						context.integrationToken &&
						(!context.shouldCopyPagePermissions || !!context.accessToken)
					) {
						return true;
					}
					return false;
				case 2:
					if (!context.hasSubpages) {
						return true;
					}
					if (context.hasSubpages && context.spaceName && context.spaceName.trim().length > 0) {
						return true;
					}
					return false;
				case 3:
					if (context.filestoreId) {
						return true;
					}
					return false;
				default:
					return true;
			}
		} else {
			if (currentIndex === 0) {
				if (context.isUploadingFile || context.filestoreId) {
					return true;
				}
				return false;
			} else if (currentIndex === 1) {
				if (context.spaceName) {
					return true;
				}
				return false;
			}
			return true;
		}
	}, [
		contentType,
		context.accessToken,
		context.filestoreId,
		context.hasSubpages,
		context.integrationToken,
		context.isUploadingFile,
		context.shouldCopyPagePermissions,
		context.shouldMapNotionMembers,
		context.spaceName,
		currentIndex,
	]);

	const getPrimaryButtonText = useCallback(() => {
		if (contentType === ContentType.NOTION) {
			if (currentIndex === 3) {
				return <FormattedMessage {...i18n.startImport} />;
			}

			return <FormattedMessage {...i18n.next} />;
		} else {
			if (currentIndex === 1) {
				return <FormattedMessage {...i18n.startImport} />;
			}

			return <FormattedMessage {...i18n.next} />;
		}
	}, [currentIndex, contentType]);

	const getSecondaryButtonText = useCallback(() => {
		if (currentIndex === 0) {
			return <FormattedMessage {...i18n.cancel} />;
		}

		return <FormattedMessage {...i18n.back} />;
	}, [currentIndex]);

	return (
		<Flex
			justifyContent="center"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			xcss={xcss({
				paddingInline: 'space.800',
				borderTop: `2px solid ${token('color.border', N30)}`,
				height: token('space.800'),
			})}
			role="footer"
		>
			<Flex
				justifyContent="space-between"
				alignItems="center"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
				xcss={xcss({
					width: '920px',
				})}
			>
				{contentType === ContentType.NOTION ? (
					<Link
						href="https://support.atlassian.com/confluence-cloud/docs/import-data-from-notion-into-confluence/"
						target="_blank"
					>
						<FormattedMessage {...i18n.learnMoreLink} />
					</Link>
				) : (
					// Empty <Box> is needed to keep Flex styling consistent
					<Box />
				)}

				<Flex gap="space.100">
					<Button
						onClick={() => {
							if (currentIndex === 0) {
								createAnalyticsEvent({
									type: 'sendUIEvent',
									data: {
										action: 'clicked',
										actionSubject: 'cancelButton',
										source: 'importContentFooter',
										attributes: {
											currentPageIndex: currentIndex,
										},
									},
								}).fire();

								closeDrawer();
								return;
							}

							createAnalyticsEvent({
								type: 'sendUIEvent',
								data: {
									action: 'clicked',
									actionSubject: 'backButton',
									source: 'importContentFooter',
									attributes: {
										currentPageIndex: currentIndex,
									},
								},
							}).fire();

							handlePageChange(currentIndex - 1);
						}}
						testId="footer-cancel-button"
					>
						{getSecondaryButtonText()}
					</Button>

					<Button
						appearance="primary"
						onClick={() => {
							createAnalyticsEvent({
								type: 'sendUIEvent',
								data: {
									action: 'clicked',
									actionSubject: 'nextButton',
									source: 'importContentFooter',
									attributes: {
										currentPageIndex: currentIndex,
										shouldCopyPagePermissions: context.shouldCopyPagePermissions,
										shouldAddRemainingMembers: context.shouldAddRemainingMembers,
										shouldMapNotionMembers: context.shouldMapNotionMembers,
									},
								},
							}).fire();
							handlePageChange(currentIndex + 1);
						}}
						isDisabled={!isNextButtonEnabled()}
					>
						{getPrimaryButtonText()}
					</Button>
				</Flex>
			</Flex>
		</Flex>
	);
};
