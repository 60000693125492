import React from 'react';
import type { FC } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import DropdownMenu, { DropdownItem } from '@atlaskit/dropdown-menu';
import { Label } from '@atlaskit/form';
import { Box, Flex, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Lozenge from '@atlaskit/lozenge';
import LockFilledIcon from '@atlaskit/icon/glyph/lock-filled';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';

import { UnlockedIcon } from '@confluence/restrictions';

import { PermissionOptions } from '../PermissionOptions';

import { NotionSvg } from './NotionSvg';

const i18n = defineMessages({
	title: {
		id: 'import-content.permissions-dropdown.title',
		defaultMessage: 'Permissions',
		description:
			'Label of a dropdown where the user selects from multiple permissions options for their imported content into Confluence',
	},
	restricted: {
		id: 'import-content.permissions-dropdown.restricted',
		defaultMessage: 'Restricted to you',
		description:
			'Option in a dropdown for selecting permissions of imported content into Confluence that makes the content restricted to the user',
	},
	default: {
		id: 'import-content.permissions-dropdown.default',
		defaultMessage: 'Default space permissions',
		description:
			'Option in a dropdown for selecting permissions of imported content into Confluence that makes the content follow default permissions',
	},
	notion: {
		id: 'import-content.permissions-dropdown.notion',
		defaultMessage: 'Notion permissions {betaLozenge}',
		description:
			'Option in a dropdown for selecting permissions of imported Notion content into Confluence that makes the content map to their Notion permissions',
	},
	betaLozenge: {
		id: 'import-content.permissions-dropdown.betaLozenge',
		defaultMessage: 'Beta',
		description: 'Lozenge stating that the option in the dropdown is in beta',
	},
	restrictedSubtext: {
		id: 'import-content.permissions-dropdown.restricted-subtext',
		defaultMessage: 'Only you can view spaces',
		description:
			'Subtext providing a short description of the restricted option of the permissions dropdown for users configuring their data to import into Confluence',
	},
	defaultSubtext: {
		id: 'import-content.permissions-dropdown.default-subtext',
		defaultMessage: 'Use default permissions set in Confluence',
		description:
			'Subtext providing a short description of the default option of the permissions dropdown for users configuring their data to import into Confluence',
	},
	notionSubtext: {
		id: 'import-content.permissions-dropdown.notion-subtext',
		defaultMessage: 'Copy teamspace or parent page permissions from Notion',
		description:
			'Subtext providing a short description of the Notion option of the permissions dropdown for users configuring their data to import into Confluence',
	},
	restrictedExplanation: {
		id: 'import-content.permissions-dropdown.restricted-explanation',
		defaultMessage:
			'Restricting the space allows you to check the imported content and permissions before other users can see it. You will be able to restore Notion permissions later. {learnMoreLink}',
		description:
			'Text below the selected option of a permissions dropdown explaining how the restricted option works',
	},
	defaultExplanation: {
		id: 'import-content.permissions-dropdown.default-explanation',
		defaultMessage: 'Allows group access as defined by Confluence defaults. {learnMoreLink}',
		description:
			'Text below the selected option of a permissions dropdown explaining how the default option works',
	},
	notionExplanation: {
		id: 'import-content.permissions-dropdown.notion-explanation',
		defaultMessage: 'Copies workspace permissions from Notion to Confluence.',
		description:
			'Text below the selected option of a permissions dropdown explaining how the Notion option works',
	},
	learnMoreLink: {
		id: 'import-content.permissions-dropdown.learn-more-link',
		defaultMessage: 'Learn more about permissions',
		description:
			'Link text following the explanation of how a selected permission option works. Clicking it will bring the user to a help page for more details',
	},
});

const PermissionsIcon: FC<{ selectedPermissions: PermissionOptions }> = ({
	selectedPermissions,
}) => {
	switch (selectedPermissions) {
		case PermissionOptions.RESTRICTED:
			return <LockFilledIcon label="restricted" primaryColor={token('color.icon.accent.red')} />;
		case PermissionOptions.NOTION:
			return (
				<Box paddingInline="space.050" paddingBlockStart="space.050">
					<NotionSvg size="small" />
				</Box>
			);
		case PermissionOptions.DEFAULT:
			return <UnlockedIcon label="default" primaryColor={token('color.icon.accent.gray')} />;
	}
};

const PermissionsText: FC<{ selectedPermissions: PermissionOptions }> = ({
	selectedPermissions,
}) => {
	switch (selectedPermissions) {
		case PermissionOptions.RESTRICTED:
			return <FormattedMessage {...i18n.restricted} />;
		case PermissionOptions.NOTION:
			return (
				<FormattedMessage
					{...i18n.notion}
					values={{
						betaLozenge: (
							<Lozenge appearance="new">
								<FormattedMessage {...i18n.betaLozenge} />
							</Lozenge>
						),
					}}
				/>
			);
		case PermissionOptions.DEFAULT:
			return <FormattedMessage {...i18n.default} />;
	}
};

const PermissionsSubtext: FC<{ selectedPermissions: PermissionOptions }> = ({
	selectedPermissions,
}) => {
	switch (selectedPermissions) {
		case PermissionOptions.RESTRICTED:
			return (
				<FormattedMessage
					{...i18n.restrictedExplanation}
					values={{
						learnMoreLink: (
							<a
								href="https://support.atlassian.com/confluence-cloud/docs/what-are-confluence-cloud-permissions-and-restrictions/#Space-permissions"
								target="_blank"
							>
								<FormattedMessage {...i18n.learnMoreLink} />
							</a>
						),
					}}
				/>
			);
		case PermissionOptions.NOTION:
			return <FormattedMessage {...i18n.notionExplanation} />;
		case PermissionOptions.DEFAULT:
			return (
				<FormattedMessage
					{...i18n.defaultExplanation}
					values={{
						learnMoreLink: (
							<a
								href="https://support.atlassian.com/confluence-cloud/docs/what-are-confluence-cloud-permissions-and-restrictions/#Space-permissions"
								target="_blank"
							>
								<FormattedMessage {...i18n.learnMoreLink} />
							</a>
						),
					}}
				/>
			);
	}
};

type PermissionsDropdownProps = {
	supportedPermissions: PermissionOptions[];
	selectedPermissions: PermissionOptions;
	setSelectedPermissions: (value: PermissionOptions) => void;
	shouldCopyPagePermissions: boolean;
};

export const PermissionsDropdown: FC<PermissionsDropdownProps> = ({
	supportedPermissions,
	selectedPermissions,
	setSelectedPermissions,
	shouldCopyPagePermissions,
}) => {
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		<Stack xcss={xcss({ width: '100%' })}>
			<Label htmlFor="space-permissions">
				<FormattedMessage {...i18n.title} />
			</Label>
			<DropdownMenu
				trigger={({ triggerRef, ...props }) => (
					<Box
						{...props}
						ref={triggerRef}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
						xcss={xcss({
							backgroundColor: 'color.background.input',
							border: `2px solid ${token('color.border.input')}`,
							borderRadius: 'border.radius.100',
							padding: 'space.100',
							':hover': {
								cursor: 'pointer',
								backgroundColor: 'color.background.input.hovered',
							},
						})}
					>
						<Flex justifyContent="space-between">
							<Flex alignItems="center" gap="space.075">
								<PermissionsIcon selectedPermissions={selectedPermissions} />{' '}
								<Box>
									<PermissionsText selectedPermissions={selectedPermissions} />
								</Box>
							</Flex>
							<ChevronDownIcon label="down" />
						</Flex>
					</Box>
				)}
			>
				{supportedPermissions.includes(PermissionOptions.RESTRICTED) && (
					<DropdownItem
						description={<FormattedMessage {...i18n.restrictedSubtext} />}
						elemBefore={
							<LockFilledIcon label="restricted" primaryColor={token('color.icon.accent.red')} />
						}
						onClick={() => {
							setSelectedPermissions(PermissionOptions.RESTRICTED);
						}}
					>
						<PermissionsText selectedPermissions={PermissionOptions.RESTRICTED} />
					</DropdownItem>
				)}
				{supportedPermissions.includes(PermissionOptions.DEFAULT) && (
					<DropdownItem
						description={<FormattedMessage {...i18n.defaultSubtext} />}
						elemBefore={
							<UnlockedIcon label="default" primaryColor={token('color.icon.accent.gray')} />
						}
						onClick={() => {
							setSelectedPermissions(PermissionOptions.DEFAULT);
						}}
					>
						<PermissionsText selectedPermissions={PermissionOptions.DEFAULT} />
					</DropdownItem>
				)}
				{shouldCopyPagePermissions && supportedPermissions.includes(PermissionOptions.NOTION) && (
					<DropdownItem
						description={<FormattedMessage {...i18n.notionSubtext} />}
						elemBefore={
							<Box paddingInline="space.025">
								<NotionSvg size="small" />
							</Box>
						}
						onClick={() => {
							setSelectedPermissions(PermissionOptions.NOTION);
						}}
					>
						<PermissionsText selectedPermissions={PermissionOptions.NOTION} />
					</DropdownItem>
				)}
			</DropdownMenu>
			<Box
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
				xcss={xcss({
					color: 'color.text.subtlest',
					paddingTop: 'space.050',
					fontSize: '12px',
				})}
			>
				<PermissionsSubtext selectedPermissions={selectedPermissions} />
			</Box>
		</Stack>
	);
};
