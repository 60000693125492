import React from 'react';
import type { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { UploadFileStep } from '../ImportNotionContentSteps/UploadFileStep';
import { ConnectDataStep } from '../ImportNotionContentSteps/ConnectDataStep';
import { SetUpSpacesStep } from '../ImportNotionContentSteps/SetUpSpacesStep';
import { ReviewDetailsStep } from '../ImportNotionContentSteps/ReviewDetailsStep';
import { StartImportStep } from '../ImportNotionContentSteps/StartImportStep';

import { ContentType } from './ImportContentDrawer';
import { Workflow } from './Workflow';

// These strings are also used in ImportFileContentWorkflow.tsx to reduce duplicates
export const i18n = defineMessages({
	content: {
		id: 'import-content.progress-tracker.content',
		defaultMessage: 'Content',
		description:
			'Label of a progress tracker step where users select the content they wish to import into Confluence',
	},
	users: {
		id: 'import-content.progress-tracker.users',
		defaultMessage: 'Users',
		description:
			'Label of a progress tracker step where users select the user settings of the content they wish to import into Confluence',
	},
	spaces: {
		id: 'import-content.progress-tracker.spaces',
		defaultMessage: 'Spaces',
		description:
			'Label of a progress tracker step where users select the space(s) restrictions of the content they wish to import into Confluence',
	},
	review: {
		id: 'import-content.progress-tracker.review',
		defaultMessage: 'Review',
		description:
			'Label of a progress tracker step where users review their selected settings of their import of outside data into Confluence',
	},
	import: {
		id: 'import-content.progress-tracker.import',
		defaultMessage: 'Start Import',
		description:
			'Label of a progress tracker step where users begin their import of outside data into Confluence',
	},
});

type ImportNotionContentWorkflowProps = {
	closeDrawer: () => void;
};

export const ImportNotionContentWorkflow: FC<ImportNotionContentWorkflowProps> = ({
	closeDrawer,
}) => {
	const { formatMessage } = useIntl();
	return (
		<Workflow
			contentType={ContentType.NOTION}
			progressTrackerStages={[
				{
					id: 'content',
					label: formatMessage(i18n.content),
					percentageComplete: 0,
					status: 'current',
				},
				{
					id: 'users',
					label: formatMessage(i18n.users),
					percentageComplete: 0,
					status: 'unvisited',
				},
				{
					id: 'spaces',
					label: formatMessage(i18n.spaces),
					percentageComplete: 0,
					status: 'unvisited',
				},
				{
					id: 'review',
					label: formatMessage(i18n.review),
					percentageComplete: 0,
					status: 'unvisited',
				},
				{
					id: 'import',
					label: formatMessage(i18n.import),
					percentageComplete: 0,
					status: 'unvisited',
				},
			]}
			steps={[UploadFileStep, ConnectDataStep, SetUpSpacesStep, ReviewDetailsStep, StartImportStep]}
			closeDrawer={closeDrawer}
		/>
	);
};
