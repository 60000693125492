import type { FetchPolicy } from 'apollo-client';

import type { Auth } from '@atlaskit/media-core';

import { getApolloClient } from '@confluence/graphql';

import type {
	ImportContentMediaTokenQuery as ImportContentMediaTokenQueryType,
	ImportContentMediaTokenQueryVariables,
} from '../../__types__/ImportContentMediaTokenQuery';
import { ImportContentMediaTokenQuery } from '../../ImportContentMediaToken.graphql';

type SetAuthProviderError = (err: any) => void;

export function isDifferenceLessThanXMinutes(
	pastDate: string,
	presentDate: string,
	limit: number,
): boolean {
	const past = new Date(pastDate);
	const present = new Date(presentDate);
	const difference = Math.abs(present.getTime() - past.getTime()) / (1000 * 60);
	return difference < limit;
}

const getMediaToken = async (
	fetchPolicy: FetchPolicy,
	contentId: string,
	setAuthProviderError: SetAuthProviderError,
) => {
	try {
		const { data } = await getApolloClient().query<
			ImportContentMediaTokenQueryType,
			ImportContentMediaTokenQueryVariables
		>({
			query: ImportContentMediaTokenQuery,
			fetchPolicy,
			variables: { contentId },
		});
		const clientId = data?.contentMediaSession?.configuration?.clientId;
		const value = data?.contentMediaSession?.token?.value;
		const fileStoreUrl = data?.contentMediaSession?.configuration?.fileStoreUrl;

		return {
			clientId: clientId || '',
			value: value || '',
			fileStoreUrl: fileStoreUrl || '',
		};
	} catch (e) {
		setAuthProviderError(e);
		return {
			clientId: '',
			value: '',
			fileStoreUrl: '',
		};
	}
};

/**
 * createAuthProvider generates a MediaToken using graphql(ImportContentMediaTokenQuery)
 *
 * This is needed for handling large file uploads because the token expires every ~10mins.
 *
 * we use apollo cache and localStorage timestamp to verify if the last query was less than 6 minutes ago
 *
 * TODO: can probably remove this localStorage piece by using apolloClient.readQuery/writeQuery, need to look into this.
 */

export const createAuthProvider =
	(contentId: string, setAuthProviderError: SetAuthProviderError) => async (): Promise<Auth> => {
		const present = new Date().toISOString();
		const past = window.localStorage.getItem('importContentTimestamp');

		if (past && isDifferenceLessThanXMinutes(present, past, 6)) {
			const {
				clientId: cachedClientId,
				value: cachedValue,
				fileStoreUrl: cachedFileStoreUrl,
			} = await getMediaToken('cache-first', contentId, setAuthProviderError);

			return {
				clientId: cachedClientId || '',
				baseUrl: cachedFileStoreUrl || '',
				token: cachedValue || '',
			};
		}

		try {
			window.localStorage.setItem('importContentTimestamp', present);
		} catch (e) {
			setAuthProviderError(e);
		}

		const { clientId, value, fileStoreUrl } = await getMediaToken(
			'network-only',
			contentId,
			setAuthProviderError,
		);

		return {
			clientId,
			baseUrl: fileStoreUrl,
			token: value,
		};
	};
