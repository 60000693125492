import React from 'react';
import type { FC } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/new';

const i18n = defineMessages({
	stopImportingHeader: {
		id: 'import-content.close-drawer-warning-modal.stop-importing-header',
		defaultMessage: 'Stop importing?',
		description:
			'Text at the top of the close drawer warning modal, asking the user if they want to stop importing and close the drawer',
	},
	stopImportingDescription: {
		id: 'import-content.close-drawer-warning-modal.stop-importing-description',
		defaultMessage:
			'You have not finished importing content. If you stop now, you will have to start over.',
		description:
			'Text in the center of the close drawer warning modal, giving the user more details about closing the drawer',
	},
	cancelButton: {
		id: 'import-content.close-drawer-warning-modal.cancel-button',
		defaultMessage: 'Cancel',
		description:
			'Secondary button at the bottom right of the modal, clicking cancel will only close the modal not the drawer',
	},
	stopImportingButton: {
		id: 'import-content.close-drawer-warning-modal.stop-importing-button',
		defaultMessage: 'Stop importing',
		description:
			"Primary action button at the bottom right of the modal, clicking 'Stop importing' will close the drawer and stop any imports",
	},
});

import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';

type ModalProps = {
	closeModal: () => void;
	isOpen: boolean;
	closeDrawer: () => void;
};

export const CloseDrawerWarningModal: FC<ModalProps> = ({ closeDrawer, closeModal, isOpen }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleCloseDrawer = () => {
		closeDrawer();
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'stopImportAndCloseDrawer',
				actionSubject: 'stopImport',
				source: 'importContentCloseDrawerWarningModal',
			},
		}).fire();
	};

	return (
		<ModalTransition>
			{isOpen && (
				<Modal onClose={closeModal}>
					<ModalHeader>
						<ModalTitle appearance="warning">
							<FormattedMessage {...i18n.stopImportingHeader} />
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<FormattedMessage {...i18n.stopImportingDescription} />
					</ModalBody>
					<ModalFooter>
						<Button appearance="subtle" onClick={closeModal} testId="modal-cancel-button">
							<FormattedMessage {...i18n.cancelButton} />
						</Button>
						<Button appearance="warning" onClick={handleCloseDrawer}>
							<FormattedMessage {...i18n.stopImportingButton} />
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</ModalTransition>
	);
};
