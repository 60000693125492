import type { FC } from 'react';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl-next';

import { Box, Flex, Grid, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Image from '@atlaskit/image';
import Button from '@atlaskit/button/new';

import { ImportContentWorkflowContext } from '../ImportContentStore';
import type { OauthMessage } from '../../ImportNotionContentSteps/ConnectDataStep';

import { translations } from './translations';
import { NotionSvg } from '../NotionSvg';

type NotionWorkspaceCardProps = {
	icon: string;
	name: string;
	userCount: number;
	setOauthData: (oauthData: OauthMessage | null) => void;
};

const NotionWorkspaceCard: FC<NotionWorkspaceCardProps> = ({
	icon,
	name,
	userCount,
	setOauthData,
}) => {
	const context = useContext(ImportContentWorkflowContext);
	const { formatMessage } = useIntl();

	return (
		<Box
			padding="space.150"
			backgroundColor="elevation.surface"
			// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values
			xcss={xcss({
				borderRadius: '5px',
				boxShadow: 'elevation.shadow.raised',
				width: '100%',
			})}
		>
			<Grid
				templateColumns={`${token('space.400')} 1fr auto`}
				columnGap="space.150"
				alignItems="center"
			>
				{icon ? <Image src={icon} alt={`${name} workspace icon`} /> : <NotionSvg size="medium" />}

				<Flex direction="column" rowGap="space.025">
					<Text size="medium" color="color.text">
						{name}
					</Text>

					<Text
						size="small"
						color="color.text.subtle"
					>{`${userCount} ${formatMessage(translations.userCountText)}`}</Text>
				</Flex>

				<Button
					onClick={() => {
						setOauthData(null);
						context.setIntegrationToken('');
					}}
				>
					Log out
				</Button>
			</Grid>
		</Box>
	);
};

export { NotionWorkspaceCard };
