import React from 'react';
import type { FC } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import Heading from '@atlaskit/heading';
import { Grid, Stack, Box, xcss } from '@atlaskit/primitives';

import { DropzoneWrapper } from '../ImportContentDrawer/UploadFile/DropzoneWrapper';

const i18n = defineMessages({
	title: {
		id: 'import-content.content-step.title',
		defaultMessage: 'Upload content',
		description:
			'Title of the step of the import-content flow that allows users import content into Confluence',
	},
	description: {
		id: 'import-content.content-step.description',
		defaultMessage:
			'Generate and upload an export file of the content you want to import. Confluence supports documents in HTML format, archived into a single ZIP file.',
		description:
			"Short overview explaining to the user that they'll need to export their content in order to import it into Confluence",
	},
});

export const ContentStep: FC = () => {
	return (
		<Grid
			templateColumns="6fr 1fr 5fr"
			gap="space.200"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			xcss={xcss({ width: '800px' })}
		>
			<Stack space="space.200">
				<Heading size="large">
					<FormattedMessage {...i18n.title} />
				</Heading>

				<FormattedMessage {...i18n.description} />
			</Stack>

			<Box />
			<DropzoneWrapper />
		</Grid>
	);
};
