import { defineMessages } from 'react-intl-next';

const translations = defineMessages({
	title: {
		id: 'import-content.connect-data-step.title',
		defaultMessage: 'Transfer users',
		description:
			'Title of the step of the import-content flow that allows users to connect their Notion users to their Confluence space when importing data',
	},
	mapNotionMembersToggleText: {
		id: 'import-content.connect-data-step.map-notion-members-toggle-text',
		defaultMessage: 'Map Notion members to their Confluence profiles',
		description:
			"Text associated with a toggle that allows the user to select whether or not they'd like to map notion members to their Confluence profiles",
	},
	mapNotionMembersDescriptionOne: {
		id: 'import-content.connect-data-step.map-notion-members-description-one',
		defaultMessage:
			'This identifies members with Confluence accounts so we can add their names to pages, comments, and @mentions. You must be a Notion workspace owner to do this.',
		description:
			'First section of a description explaining what happens when the toggle to map Notion members to their Confluence profiles is enabled',
	},
	mapNotionMembersDescriptionTwo: {
		id: 'import-content.connect-data-step.map-notion-members-description-two',
		defaultMessage: 'You’ll have an opportunity to add members without Confluence accounts next.',
		description:
			'Second section of a description explaining what happens when the toggle to map Notion members to their Confluence profiles is enabled, explaining that the user will be able to add non-Confluence accounts after this portion of the flow',
	},
	dontMapNotionMembersDescription: {
		id: 'import-content.connect-data-step.dont-map-notion-members-description',
		defaultMessage:
			'All imported pages and comments will list you as the author. User @mentions will convert to plain text.',
		description:
			'Description explaining what happens when the toggle to map Notion members to their Confluence profiles is disabled',
	},
	dontMapNotionMembersInfoBox: {
		id: 'import-content.connect-data-step.dont-map-notion-members-info-box',
		defaultMessage:
			'User profiles can’t be mapped later. To add members’ names to their content in the future, you’d need to re-run the entire import.',
		description:
			'Text on an info box shown below import-content.connect-data-step.dont-map-notion-members-description that further explains what happens when the toggle to map Notion members to their Confluence profiles is disabled',
	},
	addRemainingMembersToggleText: {
		id: 'import-content.connect-data-step.add-remaining-members-toggle-text',
		defaultMessage: 'Add remaining members to Confluence',
		description:
			"Text associated with a toggle that allows the user to select whether or not they'd like to create Confluence accounts for all Notion members in their workspace that don't already have one",
	},
	addRemainingMembersDescription: {
		id: 'import-content.connect-data-step.add-remaining-members-description',
		defaultMessage:
			'This creates a Confluence account for all Notion workspace members who don’t have one — so we can add their names to pages, comments, and @mentions.',
		description:
			'Description explaining what happens when the toggle to add remaining members to Confluence from imported Notion data is enabled',
	},
	addRemainingMembersLicensingDescription: {
		id: 'import-content.connect-data-step.add-remaining-members-licensing-description',
		defaultMessage:
			'They’ll be automatically added to your Users list, which may impact your user count and result in additional fees. <a>Learn more about licensing</a>',
		description:
			'Description explaining what happens to the users list when the toggle to add remaining members to Confluence from imported Notion data is enabled',
	},
	dontAddRemainingMembersDescription: {
		id: 'import-content.connect-data-step.dont-add-remaining-members-description',
		defaultMessage:
			'Pages and comments written by members who don’t exist in Confluence will list you as the author. Their @mentions will convert to plain text.',
		description:
			'Description explaining what happens when the toggle to add remaining members to Confluence from imported Notion data is disabled',
	},
	cantAddRemainingMembersDescription: {
		id: 'import-content.connect-data-step.cant-add-remaining-members-description',
		defaultMessage:
			'You can either ask an organization admin to invite these members to Confluence before continuing your import — or continue without them.',
		description:
			'Description explaining what happens when the user does not have permissions to add remaining members to Confluence from imported Notion data',
	},
	copyNotionPagePermissionsToggleText: {
		id: 'import-content.connect-data-step.copy-notion-page-permissions-toggle-text',
		defaultMessage: 'Copy Notion page permissions',
		description:
			"Text associated with a toggle that allows the user to select whether or not they'd like to copy Notion page permissions to their Confluence space(s) that are about to be imported",
	},
	copyNotionPagePermissionsDescription: {
		id: 'import-content.connect-data-step.copy-notion-page-permissions-description',
		defaultMessage:
			'This copies permission and user group data from Notion to Confluence. Permissions may vary if the content or access has changed since you’ve exported the file. <a>Learn more about permissions</a>',
		description:
			'Description explaining what happens when the toggle to copy Notion page permissions to their Confluence data is enabled',
	},
	dontCopyNotionPagePermissionsDescription: {
		id: 'import-content.connect-data-step.dont-copy-notion-page-permissions-description',
		defaultMessage:
			'All pages will be set to “Anyone in the space can view or edit”. You can limit access by setting space permissions.',
		description:
			'Description explaining what happens when the toggle to copy Notion page permissions to their Confluence data is disabled',
	},
	addAnIntegrationTokenButtonText: {
		id: 'import-content.connect-data-step.add-an-integration-token-button-text',
		defaultMessage: 'Add an integration token',
		description:
			'Text of a button that will open a modal, instructing the user how to add an integration token to the flow',
	},
	integrationTokenAddedButtonText: {
		id: 'import-content.connect-data-step.integration-token-added-button-text',
		defaultMessage: 'Integration token added',
		description:
			'Text of a button indicating that an integration token has been added to the flow. Clicking it will still re-open the same modal to add one',
	},
	integrationTokenModalDescription: {
		id: 'import-content.connect-data-step.integration-token-modal-description',
		defaultMessage:
			'This enables Confluence to read and import @mentions and page and comment creator / editor data. You need to be a Notion workspace owner to get the token. To create your internal integration token:',
		description:
			'Short desription at the top of a modal instructing the user on how to add an integration token to the flow.',
	},
	integrationTokenModalStepOne: {
		id: 'import-content.connect-data-step.integration-token-step-one',
		defaultMessage: 'Go to',
		description:
			'First item of a numbered list, instructing the user how to add an integration token to the flow',
	},
	integrationTokenModalStepTwo: {
		id: 'import-content.connect-data-step.integration-token-step-two',
		defaultMessage: 'Navigate to <b>Settings and members > Connections</b>',
		description:
			'Second item of a numbered list, instructing the user how to add an integration token to the flow',
	},
	integrationTokenModalStepThree: {
		id: 'import-content.connect-data-step.integration-token-step-three',
		defaultMessage: 'Click on <b>Develop or manage integrations</b> link',
		description:
			'Third item of a numbered list, instructing the user how to add an integration token to the flow',
	},
	integrationTokenModalStepFour: {
		id: 'import-content.connect-data-step.integration-token-step-four',
		defaultMessage: 'Click on <b>New integration</b> button',
		description:
			'Fourth item of a numbered list, instructing the user how to add an integration token to the flow',
	},
	integrationTokenModalStepFive: {
		id: 'import-content.connect-data-step.integration-token-step-five',
		defaultMessage: 'Enter any name, e.g. “Confluence import”, and click <b>Submit</b>',
		description:
			'Fifth item of a numbered list, instructing the user how to add an integration token to the flow',
	},
	integrationTokenModalPermissions: {
		id: 'import-content.connect-data-step.integration-token-modal-permissions',
		defaultMessage: 'Next, give the integration permission to read your Notion pages:',
		description:
			'Some short text in the middle of an ordered list describing what the next portion of the set of instructions will entail for granting a Notion integration the proper permissions it needs for the Confluence import',
	},
	integrationTokenModalStepSix: {
		id: 'import-content.connect-data-step.integration-token-step-six',
		defaultMessage: 'Navigate to <b>Capabilities</b> in the left side navigation',
		description:
			'Sixth item of a numbered list, instructing the user how to add an integration token to the flow',
	},
	integrationTokenModalStepSeven: {
		id: 'import-content.connect-data-step.integration-token-step-seven',
		defaultMessage:
			'Enable <b>Read comments</b> and <b>Read user information including email addresses</b>, and click <b>Save changes</b>. Keep this tab open.',
		description:
			'Seventh item of a numbered list, instructing the user how to add an integration token to the flow',
	},
	integrationTokenModalStepEight: {
		id: 'import-content.connect-data-step.integration-token-step-eight',
		defaultMessage: 'In a new tab, navigate to the Notion page you’ve exported',
		description:
			'Eighth item of a numbered list, instructing the user how to add an integration token to the flow',
	},
	integrationTokenModalStepNine: {
		id: 'import-content.connect-data-step.integration-token-step-nine',
		defaultMessage: 'Open the ••• menu in the top right',
		description:
			'Seventh item of a numbered list, instructing the user how to add an integration token to the flow',
	},
	integrationTokenModalStepTen: {
		id: 'import-content.connect-data-step.integration-token-step-ten',
		defaultMessage: 'Scroll down to <b>Connect to</b>',
		description:
			'Tenth item of a numbered list, instructing the user how to add an integration token to the flow',
	},
	integrationTokenModalStepEleven: {
		id: 'import-content.connect-data-step.integration-token-step-eleven',
		defaultMessage: 'Search for your integration and select it (you may need to refresh the page)',
		description:
			'Eleventh item of a numbered list, instructing the user how to add an integration token to the flow',
	},
	integrationTokenModalStepTwelve: {
		id: 'import-content.connect-data-step.integration-token-step-twelve',
		defaultMessage: 'Click <b>Confirm</b>',
		description:
			'Twelfth item of a numbered list, instructing the user how to add an integration token to the flow',
	},
	integrationTokenModalRepeat: {
		id: 'import-content.connect-data-step.integration-token-modal-repeat',
		defaultMessage:
			'<b>Important:</b> Repeat steps 8-12 for all top-level pages in your workspace.',
		description:
			'Some short text in the middle of an ordered list instructing the user to repeat some above steps for all top-level pages in their Notion workspace',
	},
	integrationTokenModalStepThirteen: {
		id: 'import-content.connect-data-step.integration-token-step-thirteen',
		defaultMessage: 'Return to your integration token tab',
		description:
			'Thirteenth item of a numbered list, instructing the user how to add an integration token to the flow',
	},
	integrationTokenModalStepFourteen: {
		id: 'import-content.connect-data-step.integration-token-step-fourteen',
		defaultMessage: 'Navigate to <b>Secrets</b> in the left side navigation',
		description:
			'Fourteenth item of a numbered list, instructing the user how to add an integration token to the flow',
	},
	integrationTokenModalStepFifteen: {
		id: 'import-content.connect-data-step.integration-token-step-fifteen',
		defaultMessage: 'In Internal Integration Secret field, click <b>Show</b> and then <b>Copy</b>',
		description:
			'Fifteenth item of a numbered list, instructing the user how to add an integration token to the flow',
	},
	integrationTokenModalPaste: {
		id: 'import-content.connect-data-step.integration-token-modal-paste',
		defaultMessage: 'Once you have the integration token, paste it here to continue.',
		description:
			'Final piece of some instructions, telling the user to paste the integration token they just generated outside of Confluence into the text field below',
	},
	integrationTokenTextFieldLabel: {
		id: 'import-content.connect-data-step.integration-token-text-field-label',
		defaultMessage: 'Notion integration token',
		description:
			'The label above a text field where the user should be pasting their newly generated integration token',
	},
	integrationTokenTextFieldPlaceholder: {
		id: 'import-content.connect-data-step.integration-token-text-field-placeholder',
		defaultMessage: 'Paste the token here',
		description:
			'The placeholder text of a text field where the user should be pasting their newly generated integration token',
	},
	addAnAccessTokenButtonText: {
		id: 'import-content.connect-data-step.add-an-access-token-button-text',
		defaultMessage: 'Add an access token',
		description:
			'Text of a button that will open a modal, instructing the user how to add an access token to the flow',
	},
	accessTokenAddedButtonText: {
		id: 'import-content.connect-data-step.access-token-added-button-text',
		defaultMessage: 'Access token added',
		description:
			'Text of a button indicating that an access token has been added to the flow. Clicking it will still re-open the same modal to add one',
	},
	accessTokenModalDescription: {
		id: 'import-content.connect-data-step.access-token-modal-description',
		defaultMessage:
			'This enables Confluence to read and import permission and user group data. To get an access token:',
		description:
			'Short desription at the top of a modal instructing the user on how to add an access token to the flow.',
	},
	accessTokenModalStepOne: {
		id: 'import-content.connect-data-step.access-token-step-one',
		defaultMessage:
			'Go to <a>Notion</a> in your browser make sure you are logged into just one account.',
		description:
			'First item of a numbered list, instructing the user how to add an access token to the flow',
	},
	accessTokenModalStepTwo: {
		id: 'import-content.connect-data-step.access-token-step-two',
		defaultMessage:
			'Right-click anywhere on the screen and select <b>Inspect Element</b> to open Developer tools.',
		description:
			'Second item of a numbered list, instructing the user how to add an access token to the flow',
	},
	accessTokenModalStepTwoFirefoxEdge: {
		id: 'import-content.connect-data-step.access-token-step-two-firefox-edge',
		defaultMessage:
			'Right-click anywhere on the screen and click <b>Inspect</b> to open Developer Tools.',
		description:
			'Second item of a numbered list, instructing the user how to add an access token to the flow',
	},
	accessTokenModalStepThree: {
		id: 'import-content.connect-data-step.access-token-step-three',
		defaultMessage: 'Go to the <b>Storage</b> tab.',
		description:
			'Third item of a numbered list, instructing the user how to add an access token to the flow',
	},
	accessTokenModalStepThreeEdge: {
		id: 'import-content.connect-data-step.access-token-step-three-edge',
		defaultMessage: 'Go to the <b>Application</b> tab.',
		description:
			'Third item of a numbered list, instructing the user how to add an access token to the flow',
	},
	accessTokenModalStepFour: {
		id: 'import-content.connect-data-step.access-token-step-four',
		defaultMessage:
			'In the sidebar, click the ⏵ toggle next to <b>Cookies</b> folder to expand it.',
		description:
			'Fourth item of a numbered list, instructing the user how to add an access token to the flow',
	},
	accessTokenModalStepFive: {
		id: 'import-content.connect-data-step.access-token-step-five',
		defaultMessage: 'Click on <b>www.notion.so</b>.',
		description:
			'Fifth item of a numbered list, instructing the user how to add an access token to the flow',
	},
	accessTokenModalStepSix: {
		id: 'import-content.connect-data-step.access-token-step-six',
		defaultMessage: 'Find the entry named <b>token_v2</b> and double-click on it to open details.',
		description:
			'Sixth item of a numbered list, instructing the user how to add an access token to the flow',
	},
	accessTokenModalStepSixFirefoxEdge: {
		id: 'import-content.connect-data-step.access-token-step-six-firefox-edge',
		defaultMessage: 'Find the entry named <b>token_v2</b>.',
		description:
			'Sixth item of a numbered list, instructing the user how to add an access token to the flow',
	},
	accessTokenModalStepSeven: {
		id: 'import-content.connect-data-step.access-token-step-seven',
		defaultMessage: 'Select the text in the <b>Value</b> field, then copy (Cmd/Ctrl + C)',
		description:
			'Seventh item of a numbered list, instructing the user how to add an access token to the flow',
	},
	accessTokenModalStepSevenFirefoxEdge: {
		id: 'import-content.connect-data-step.access-token-step-seven-firefox-edge',
		defaultMessage: 'Select the text in the <b>Value</b> field, then copy (Cmd/Ctrl + C)',
		description: 'Double-click the text in the Value column to select it, then copy it.',
	},
	accessTokenModalPaste: {
		id: 'import-content.connect-data-step.access-token-modal-paste',
		defaultMessage: 'After you have the access token, paste it here to continue.',
		description:
			'Final piece of some instructions, telling the user to paste the access token they just generated outside of Confluence into the text field below',
	},
	accessTokenTextFieldLabel: {
		id: 'import-content.connect-data-step.access-token-text-field-label',
		defaultMessage: 'Notion access token',
		description:
			'The label above a text field where the user should be pasting their newly generated access token',
	},
	accessTokenTextFieldPlaceholder: {
		id: 'import-content.connect-data-step.access-token-text-field-placeholder',
		defaultMessage: 'Paste the access token here',
		description:
			'The placeholder text of a text field where the user should be pasting their newly generated access token',
	},
	modalCancelButtonText: {
		id: 'import-content.connect-data-step.modal-cancel-button-text',
		defaultMessage: 'Cancel',
		description: 'The text of a button that when clicked, will close the modal',
	},
	modalSubmitButtonText: {
		id: 'import-content.connect-data-step.modal-submit-button-text',
		defaultMessage: 'Submit',
		description:
			'The text of a button that when clicked, will submit the data the user has entered from the modal',
	},
	loginToNotionButtonText: {
		id: 'import-content.connect-data-step.login-to-notion-button-text',
		defaultMessage: 'Log in to Notion',
		description:
			'The text of a button that when clicked, will start the authentication flow with Notion',
	},
	userCountText: {
		id: 'import-content.connect-data-step.user-count-text',
		defaultMessage: 'members',
		description: 'The text that appears after the number of users in a Notion workspace',
	},
	accessTokenErrorTooShort: {
		id: 'import-content.connect-data-step.access-token-errors-step-one',
		defaultMessage: 'Please enter a valid Notion access token.',
		description:
			"Error message that appears when the user tries to submit an access token that doesn't match the expected format",
	},
	accessTokenErrorInvalidPrefix: {
		id: 'import-content.connect-data-step.access-token-errors-step-two',
		defaultMessage: 'Access token must start with `secret`',
		description:
			"Error message that appears when the user tries to submit an access token that doesn't match the expected format",
	},
	notionConnectionError: {
		id: 'import-content.connect-data-step.notion-connection-error',
		defaultMessage: 'We weren’t able to connect to Notion automatically. Try adding a token.',
		description:
			'Text that appears when OAuth connection does not work and user must manually connect to Notion',
	},
});

export { translations };
