import React from 'react';
import type { FC } from 'react';

import SVG from '@atlaskit/icon/svg';
import type { Size } from '@atlaskit/icon';

type NotionSvgProps = {
	size?: Size;
};

export const NotionSvg: FC<NotionSvgProps> = ({ size }) => (
	<SVG size={size ?? 'medium'} label="Notion">
		<path
			d="M4.47337 4.21121C5.22046 4.81819 5.50072 4.77188 6.90365 4.67827L20.1295 3.88411C20.41 3.88411 20.1768 3.60427 20.0832 3.55778L17.8867 1.96985C17.4658 1.64308 16.9051 1.26888 15.8304 1.36249L3.02375 2.29656C2.55669 2.34287 2.46341 2.5764 2.6494 2.76357L4.47337 4.21121ZM5.26743 7.2935V21.2094C5.26743 21.9573 5.64116 22.2371 6.48233 22.1909L21.0176 21.3498C21.8592 21.3035 21.9529 20.7891 21.9529 20.1816V6.359C21.9529 5.75245 21.7196 5.42536 21.2044 5.47204L6.01494 6.359C5.45437 6.40612 5.26738 6.68652 5.26738 7.2935H5.26743ZM19.6166 8.03998C19.7098 8.46068 19.6166 8.88101 19.1951 8.92831L18.4948 9.06785V19.3415C17.8867 19.6683 17.326 19.8551 16.8587 19.8551C16.1107 19.8551 15.9233 19.6215 15.363 18.9214L10.7819 11.7298V18.6879L12.2316 19.015C12.2316 19.015 12.2316 19.8551 11.062 19.8551L7.83787 20.0422C7.74421 19.8551 7.83787 19.3885 8.16491 19.2951L9.00627 19.0619V9.86196L7.8381 9.76835C7.7444 9.34764 7.97774 8.74104 8.63254 8.69397L12.0913 8.46082L16.8587 15.746V9.30133L15.6432 9.16184C15.5499 8.64752 15.9233 8.27407 16.3907 8.22777L19.6166 8.03998ZM1.94848 1.03577L15.2694 0.0547744C16.9053 -0.0855233 17.3262 0.00846521 18.3543 0.755318L22.6065 3.744C23.3082 4.25794 23.542 4.39786 23.542 4.95811V21.3498C23.542 22.3771 23.1678 22.9846 21.8594 23.0776L6.38976 24.0118C5.40758 24.0586 4.94015 23.9186 4.42578 23.2644L1.29439 19.2016C0.733341 18.4537 0.5 17.8942 0.5 17.2397V2.66963C0.5 1.82955 0.874348 1.12877 1.94848 1.03577Z"
			fill="currentColor"
		/>
	</SVG>
);
