import gql from 'graphql-tag';

export const GetOrgAdminStatusQuery = gql`
	query GetOrgAdminStatusQuery($principalId: String!, $resourceId: String!) {
		permitted(
			principalId: $principalId
			resourceId: $resourceId
			permissionId: "manage/org"
			dontRequirePrincipalInSite: true
		)
	}
`;
