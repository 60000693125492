import React from 'react';
import type { FC } from 'react';

import { StartImportCard } from '../ImportContentDrawer/StartImport/StartImportCard';

export const StartImportStep: FC<{
	handlePageChange: (idx: number) => void;
}> = ({ handlePageChange }) => {
	return <StartImportCard handlePageChange={handlePageChange} />;
};
