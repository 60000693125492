const REDIRECT_URI_STAGING = 'https://api.stg.atlassian.com/api/v1/migrations/import/oauth/webhook';
const REDIRECT_URI_PROD = 'https://api.atlassian.com/api/v1/migrations/import/oauth/webhook';
const NOTION_CLIENT_ID = '0ea15fad-8bcf-4991-9b0b-b95e6bfd8840';

const getRedirectUri = (environment: string) => {
	switch (environment) {
		case 'DEVELOPMENT':
		case 'STAGING':
			return REDIRECT_URI_STAGING;

		case 'PRODUCTION':
		default:
			return REDIRECT_URI_PROD;
	}
};

const getAuthorizationUrl = (environment: string) => {
	const authorizationUrl = new URL('https://api.notion.com/v1/oauth/authorize');

	authorizationUrl.searchParams.append('client_id', NOTION_CLIENT_ID);
	authorizationUrl.searchParams.append('response_type', 'code');
	authorizationUrl.searchParams.append('owner', 'user');
	authorizationUrl.searchParams.append('redirect_uri', getRedirectUri(environment));

	return authorizationUrl.toString();
};

export { getRedirectUri, getAuthorizationUrl };
