import React, { useContext, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { styled } from '@compiled/react';
import type { FC } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import Heading from '@atlaskit/heading';
import Spinner from '@atlaskit/spinner';
import CheckIcon from '@atlaskit/icon/glyph/check';
import Image from '@atlaskit/image';
import { Inline, Box, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { G300 } from '@atlaskit/theme/colors';
import Button from '@atlaskit/button';

import { useSessionData } from '@confluence/session-data';

import { RunImportMutation } from '../../RunImport.graphql';
import type { RunImport } from '../../__types__/RunImportMutation';
import { ImportContentWorkflowContext, useImportContentStore } from '../ImportContentStore';
import { ContentType } from '../ImportContentDrawer';
import RunImportDefault from '../../images/runImportOnboardingLight.svg';

import { StartImportError } from './StartImportError';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubDescription = styled.div({
	color: token('color.text', '#172B4D'),
});

const ImageStyles = xcss({ width: '320px' });
const StackStyles = xcss({
	width: '500px',
	gap: 'space.300',
	textAlign: 'center',
});

const i18n = defineMessages({
	startImportHeader: {
		id: 'import-content.start-import-card.start-import-header',
		defaultMessage: 'Your import is in progress',
		description:
			'Text in the center of the Start Import step, informing the user their import is in progress',
	},
	startImportDescription: {
		id: 'import-content.start-import-card.start-import-description',
		defaultMessage:
			'This could take a few minutes to a few hours depending on the file size. You’ll receive an email when we’re done',
		description:
			'Text underneath the start-import-header, informing the user about long wait times for the import, the user will receive an email if successful',
	},
	uploadingContent: {
		id: 'import-content.start-import-card.uploading-content',
		defaultMessage: 'Uploading content',
		description:
			'Text message beside the spinner/check icon, informing the user whether the content is uploaded or uploading',
	},
	creatingSpaces: {
		id: 'import-content.start-import-card.creating-spaces',
		defaultMessage: 'Creating spaces',
		description:
			'Text message beside the spinner icon, informing the user that spaces are being imported onto Confluence',
	},
	doNotCloseWhileUploading: {
		id: 'import-content.start-import-card.do-not-close-while-uploading',
		defaultMessage: 'Please don’t close this screen until your content has finished uploading.',
		description:
			'Text message beside the spinner icon, informing the user that spaces are being imported onto Confluence',
	},
	closeAndContinueImporting: {
		id: 'import-content.start-import-card.close-and-continue-importing',
		defaultMessage: 'Close to continue import in the background',
		description:
			'Button text message, the user is able to close the drawer and continue importing as a background task',
	},
});

export const StartImportCard: FC<{
	handlePageChange: (idx: number) => void;
}> = ({ handlePageChange }) => {
	const { userId: accountId, cloudId, orgId } = useSessionData();

	const {
		filestoreId,
		hasSubpages,
		accessToken,
		integrationToken,
		selectedPermissions,
		shouldMapNotionMembers,
		spaceName,
		shouldCopyPagePermissions,
		shouldAddRemainingMembers,
		setIsStartingImport,
	} = useContext(ImportContentWorkflowContext);

	const [{ contentType: application }, { setShowImportContentDrawer }] = useImportContentStore();

	const [runImport, { error }] = useMutation<RunImport>(RunImportMutation, {
		onCompleted: () => setIsStartingImport(false),
		onError: () => {}, // added for error tests to pass
	});

	useEffect(() => {
		if (filestoreId) {
			const importPageData = application === ContentType.NOTION ? shouldMapNotionMembers : false;
			const importUsers = application === ContentType.NOTION ? shouldAddRemainingMembers : false;
			const fullImport = application === ContentType.NOTION ? !hasSubpages : false;
			const spaceNameCheck = fullImport ? '' : spaceName;

			void runImport({
				variables: {
					input: {
						orgId,
						filestoreId,
						baseUrl: window.location.origin,
						application,
						cloudId,
						accountId,
						integrationToken: shouldMapNotionMembers ? integrationToken : '',
						accessToken: shouldCopyPagePermissions ? accessToken : '',
						importPermissions: selectedPermissions,
						importPageData,
						spaceName: spaceNameCheck,
						fullImport,
						importUsers,
					},
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filestoreId]);

	if (error) {
		return <StartImportError handlePageChange={handlePageChange} />;
	}

	return (
		<Stack alignInline="center">
			<Box xcss={ImageStyles}>
				<Image src={RunImportDefault} alt="run import default image" />
			</Box>
			<Stack xcss={StackStyles}>
				<Heading size="medium">
					<FormattedMessage {...i18n.startImportHeader} />
				</Heading>
				<SubDescription>
					<FormattedMessage {...i18n.startImportDescription} />
				</SubDescription>
				<Inline space="space.050" alignInline="center">
					{filestoreId ? (
						<CheckIcon
							size="small"
							label="File Uploaded"
							primaryColor={token('color.icon.success', G300)}
							data-testid="upload-file-successful"
						/>
					) : (
						<Spinner
							size="small"
							interactionName="load"
							appearance="inherit"
							label="File Uploading"
						/>
					)}
					<FormattedMessage {...i18n.uploadingContent} />
				</Inline>
				{filestoreId && (
					<Inline space="space.050" alignInline="center">
						<Spinner
							size="small"
							interactionName="load"
							appearance="inherit"
							data-testid="import-file-loading-spinner"
						/>
						<FormattedMessage {...i18n.creatingSpaces} />
					</Inline>
				)}
				{!filestoreId ? (
					<SubDescription>
						<FormattedMessage {...i18n.doNotCloseWhileUploading} />
					</SubDescription>
				) : (
					<div data-testid="close-import-drawer">
						<Button onClick={() => setShowImportContentDrawer(false)}>
							<FormattedMessage {...i18n.closeAndContinueImporting} />
						</Button>
					</div>
				)}
			</Stack>
		</Stack>
	);
};
