import React, { useContext } from 'react';
import type { ChangeEvent, FC } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { Box, Grid, Stack, xcss } from '@atlaskit/primitives';
import { Field } from '@atlaskit/form';
import Heading from '@atlaskit/heading';
import Image from '@atlaskit/image';
import Textfield from '@atlaskit/textfield';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { PermissionOptions } from '../PermissionOptions';
import PageWithSubpagesGraphic from '../images/pageWithSubpagesGraphic.png';
import { PermissionsDropdown } from '../ImportContentDrawer/PermissionsDropdown';
import { ImportContentWorkflowContext } from '../ImportContentDrawer/ImportContentStore';
import { i18n as setUpSpacesI18n } from '../ImportNotionContentSteps/SetUpSpacesStep';

const i18n = defineMessages({
	description: {
		id: 'import-content.space-step.description',
		defaultMessage:
			'Name the new Confluence space we’ll import your content to and set the permissions to determine who can access it.',
		description:
			"Short overview explaining to the user that they'll need to name and set permissions for the space their content will get imported to on Confluence",
	},
});

export const SpaceStep: FC = () => {
	const context = useContext(ImportContentWorkflowContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<Grid
			templateColumns="6fr 1fr 5fr"
			gap="space.200"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			xcss={xcss({ width: '800px' })}
		>
			<Stack space="space.200" alignInline="start">
				<Heading size="large">
					<FormattedMessage {...setUpSpacesI18n.singularTitle} />
				</Heading>
				<FormattedMessage {...i18n.description} />
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
				<Stack xcss={xcss({ width: '100%' })}>
					<Field
						name="Name your space"
						label={<FormattedMessage {...setUpSpacesI18n.spaceNameLabel} />}
						isRequired
					>
						{({ fieldProps }) => (
							<Textfield
								{...fieldProps}
								value={context.spaceName}
								onChange={(event: ChangeEvent<HTMLInputElement>) =>
									context.setSpaceName(event.target.value)
								}
							/>
						)}
					</Field>
				</Stack>
				<PermissionsDropdown
					supportedPermissions={[PermissionOptions.RESTRICTED, PermissionOptions.DEFAULT]}
					shouldCopyPagePermissions={context.shouldCopyPagePermissions}
					selectedPermissions={context.selectedPermissions}
					setSelectedPermissions={(selectedPermission: PermissionOptions) => {
						createAnalyticsEvent({
							type: 'sendUIEvent',
							data: {
								action: 'select',
								actionSubject: 'permissionsDropdown',
								source: 'importContentSpaceStep',
								attributes: {
									selectedPermission,
									contentType: 'File',
								},
							},
						}).fire();
						context.setSelectedPermissions(selectedPermission);
					}}
				/>
			</Stack>
			<Box />
			<Image src={PageWithSubpagesGraphic} />
		</Grid>
	);
};
