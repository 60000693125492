import gql from 'graphql-tag';

export const RunImportMutation = gql`
	mutation RunImport($input: RunImportInput!) {
		runImport(input: $input) {
			taskId
			success
			errors {
				statusCode
				message
			}
		}
	}
`;
