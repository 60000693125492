import React from 'react';
import { styled } from '@compiled/react';
import type { FC } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import Image from '@atlaskit/image';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import RunImportError from '../../images/runImportErrorGeneral.svg';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubDescription = styled.div({
	color: token('color.text', '#172B4D'),
});

const ImageStyles = xcss({ width: '320px' });
const StackStyles = xcss({
	width: '500px',
	gap: 'space.300',
	textAlign: 'center',
});

const i18n = defineMessages({
	errorHeader: {
		id: 'import-content.start-import-step.error-header',
		defaultMessage: 'Couldn’t upload file',
		description:
			'Text in the center of the Start Import step, informing the user there has been an error with upload',
	},
	errorDescription: {
		id: 'import-content.start-import-step.error-description',
		defaultMessage:
			'Something went wrong while we were trying to upload your file. Please wait a few minutes and try again.',
		description:
			'Text underneath the error-header, informing the user to try again after a few minutes',
	},
	tryAgain: {
		id: 'import-content.start-import-step.try-again',
		defaultMessage: 'Try again',
		description:
			'Text for button underneath the error-description, informing the user to try again',
	},
});

export const StartImportError: FC<{
	handlePageChange: (idx: number) => void;
}> = ({ handlePageChange }) => {
	return (
		<Stack alignInline="center">
			<Box xcss={ImageStyles}>
				<Image src={RunImportError} alt="run import error image" />
			</Box>
			<Stack xcss={StackStyles}>
				<Heading size="medium">
					<FormattedMessage {...i18n.errorHeader} />
				</Heading>
				<SubDescription>
					<FormattedMessage {...i18n.errorDescription} />
				</SubDescription>
				<Box>
					<Button onClick={() => handlePageChange(0)}>
						<FormattedMessage {...i18n.tryAgain} />
					</Button>
				</Box>
			</Stack>
		</Stack>
	);
};
