import React from 'react';
import type { FC } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { Box, Flex, Stack, xcss, Text, Grid } from '@atlaskit/primitives';
import Skeleton from '@atlaskit/skeleton';
import Heading from '@atlaskit/heading';
import Image from '@atlaskit/image';
import { token } from '@atlaskit/tokens';
import CommentIcon from '@atlaskit/icon/glyph/comment';
import LockFilledIcon from '@atlaskit/icon/glyph/lock-filled';
import Tooltip from '@atlaskit/tooltip';

import { UnlockedIcon } from '@confluence/restrictions';

import Alexander from '../images/alexander.png';
import Milo from '../images/milo.png';
import Vania from '../images/vania.png';
import NewUsersIcon from '../images/newUsersIcon.png';

const i18n = defineMessages({
	previewTitle: {
		id: 'import-content.user-data-preview.preview-title',
		defaultMessage: 'User data preview',
		description:
			'Title at the top of a graphic previewing how some selected options will affect what imported Confluence pages look like after the user imports data into them',
	},
	createdBy: {
		id: 'import-content.user-data-preview.created-by',
		defaultMessage: 'Created by {createdBySubject}',
		description:
			'Example in the user data preview of what the author text will look like on a Confluence page after data is imported',
	},
	createdBySubject: {
		id: 'import-content.user-data-preview.created-by-subject',
		defaultMessage: 'you',
		description:
			'This text will either be "you" or an example name, it is to follow the import-content.user-data-preview.created-by text as part of the preview of how author text looks on a Confluence page',
	},
	commentsTitle: {
		id: 'import-content.user-data-preview.comments-title',
		defaultMessage: '10 comments',
		description:
			'Sample text showing what the heading of a comment section of a Confluence page will look like',
	},
	commentAuthor: {
		id: 'import-content.user-data-preview.comment-author',
		defaultMessage: 'You',
		description: 'Author of an example of a comment on a Confluence page',
	},
	restrictedTooltip: {
		id: 'import-content.user-data-preview.restricted-tooltip',
		defaultMessage: 'No restrictions',
		description:
			'Sample tooltip in a preview infobox signaling that the content to be created will be restricted to the user',
	},
	specificTooltip: {
		id: 'import-content.user-data-preview.specific-tooltip',
		defaultMessage: 'Only specific people can view and edit',
		description:
			'Sample tooltip in a preview infobox signaling that the content to be created will be restricted to only specific people',
	},
	newUsersLabel: {
		id: 'import-content.user-data-preview.new-users-label',
		defaultMessage: 'New users',
		description:
			'Title at the top of a graphic previewing how some selected options will affect how many Confluence users would be added',
	},
	newUsersTitle: {
		id: 'import-content.user-data-preview.new-users-title',
		defaultMessage: 'Up to {maximumNewUsers, plural, one {# user} other {# users}}',
		description:
			'Title of a card that shows the maximum number of users that will be added to a Confluence account after import',
	},
	noNewUsersTitle: {
		id: 'import-content.user-data-preview.no-new-users-title',
		defaultMessage: '0 users',
		description:
			'Title of a card that shows that no new users will be added to a Confluence account after import',
	},
	newUsersDescription: {
		id: 'import-content.user-data-preview.new-users-description',
		defaultMessage:
			'These users will be added to your subscription, if they aren’t in your Confluence yet.',
		description:
			'Description indicating the maximum number of users that will be added to a Confluence account after import',
	},
	noNewUsersDescription: {
		id: 'import-content.user-data-preview.no-new-users-description',
		defaultMessage:
			'No new users will be added to your subscription. Only existing users will be mapped.',
		description:
			'Description indicating that no new userswill be added to a Confluence account after import',
	},
});

type UserDataPreviewProps = {
	isAuthenticated: boolean;
	importUserData: boolean;
	addRemainingMembers: boolean;
	copyPagePermissions: boolean;
	userCount?: number;
	oauthError: boolean;
};

export const UserDataPreview: FC<UserDataPreviewProps> = ({
	isAuthenticated,
	importUserData,
	addRemainingMembers,
	copyPagePermissions,
	userCount = 0,
	oauthError,
}) => {
	const getNewUsersTitle = () => {
		if (!isAuthenticated) {
			return '--';
		}

		if (!addRemainingMembers) {
			return <FormattedMessage {...i18n.noNewUsersTitle} />;
		}

		return <FormattedMessage {...i18n.newUsersTitle} values={{ maximumNewUsers: userCount }} />;
	};

	const getNewUsersDescription = () => {
		if (!addRemainingMembers) {
			<FormattedMessage {...i18n.noNewUsersDescription} />;
		}

		return <FormattedMessage {...i18n.newUsersDescription} />;
	};

	return (
		<Box
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			xcss={xcss({ height: 'fit-content', borderRadius: '5px' })}
			backgroundColor="elevation.surface.sunken"
			padding="space.200"
			role="region"
			aria-labelledby="Preview"
		>
			<Stack space="space.200">
				<Stack space="space.100">
					<Heading level="h200">
						<FormattedMessage {...i18n.previewTitle} />
					</Heading>

					<Box
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
						xcss={xcss({
							backgroundColor: 'elevation.surface',
							boxShadow: 'elevation.shadow.raised',
							borderRadius: '5px',
							fontSize: '11px',
						})}
						padding="space.400"
					>
						<Stack space="space.200">
							<Flex gap="space.200" justifyContent="space-between">
								<Flex gap="space.150">
									<Image
										src={importUserData ? Alexander : Milo}
										height="32px"
										width="32px"
										alt=""
									/>

									<Stack space="space.075">
										<FormattedMessage
											{...i18n.createdBy}
											values={{
												createdBySubject: (
													<b>
														{importUserData ? (
															'Oliver Onion'
														) : (
															<FormattedMessage {...i18n.createdBySubject} />
														)}
													</b>
												),
											}}
										/>

										<Skeleton width="100px" height="6px" />
									</Stack>
								</Flex>

								<Stack
									alignInline="end"
									space="space.050"
									// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
									xcss={xcss({
										marginInlineEnd: 'space.negative.200',
									})}
								>
									<Tooltip
										content={
											importUserData && copyPagePermissions ? (
												<FormattedMessage {...i18n.specificTooltip} />
											) : (
												<FormattedMessage {...i18n.restrictedTooltip} />
											)
										}
									>
										{importUserData && copyPagePermissions ? (
											<LockFilledIcon
												label="restricted"
												primaryColor={token('color.icon.accent.red')}
											/>
										) : (
											<UnlockedIcon
												label="unrestricted"
												primaryColor={token('color.icon.accent.gray')}
											/>
										)}
									</Tooltip>
								</Stack>
							</Flex>

							<Stack>
								<Box paddingBlock="space.100">
									<Skeleton width="100%" height="6px" />
								</Box>

								<Flex gap="space.100" alignItems="center">
									{importUserData ? (
										<Box
											// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
											xcss={xcss({
												backgroundColor: 'color.background.neutral',
												borderRadius: 'border.radius.300',
											})}
											paddingInline="space.100"
											paddingBlock="space.025"
										>
											@Helena Hubbard
										</Box>
									) : (
										<span>Helena Hubbard</span>
									)}

									<Skeleton width={importUserData ? '104px' : '131px'} height="6px" />
								</Flex>

								<Box paddingBlock="space.100">
									<Skeleton width="100%" height="6px" />
								</Box>

								<Box paddingBlock="space.100">
									<Skeleton width="180px" height="6px" />
								</Box>
							</Stack>
						</Stack>

						<Stack
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
							xcss={xcss({ marginTop: 'space.300' })}
							space="space.150"
						>
							<Heading level="h200">
								<Flex gap="space.100" alignItems="center">
									<CommentIcon label="comment" /> <FormattedMessage {...i18n.commentsTitle} />
								</Flex>
							</Heading>

							<Flex gap="space.150">
								<Box>
									<Image src={importUserData ? Vania : Milo} height="32px" width="32px" alt="" />
								</Box>

								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */}
								<Stack space="space.100" xcss={xcss({ width: '100%' })}>
									<div>
										<b>
											{importUserData ? 'Norah Nori' : <FormattedMessage {...i18n.commentAuthor} />}
										</b>
									</div>

									<Skeleton width="100%" height="6px" />
									<Skeleton width="160px" height="6px" />
								</Stack>
							</Flex>
						</Stack>
					</Box>
				</Stack>

				{!oauthError && (
					<>
						<Stack space="space.100">
							<Heading level="h200">
								<FormattedMessage {...i18n.newUsersLabel} />
							</Heading>
							<Box
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
								xcss={xcss({
									boxShadow: 'elevation.shadow.raised',
									borderRadius: '5px',
								})}
								backgroundColor="elevation.surface"
								paddingInlineEnd="space.200"
							>
								<Grid templateColumns="auto 1fr" columnGap="space.200">
									<Box
										backgroundColor="color.background.accent.blue.subtlest"
										padding="space.150"
										// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values
										xcss={xcss({
											width: '100%',
											height: '100%',
										})}
									>
										<Grid
											justifyItems="center"
											alignItems="center"
											// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values
											xcss={xcss({
												width: '100%',
												height: '100%',
											})}
										>
											<Image src={NewUsersIcon} width={60} height={60} alt="" />
										</Grid>
									</Box>
									<Box paddingBlock="space.150">
										<Stack space="space.100">
											<Heading level="h500">{getNewUsersTitle()}</Heading>
											<Text size="small">{getNewUsersDescription()}</Text>
										</Stack>
									</Box>
								</Grid>
							</Box>
						</Stack>
					</>
				)}
			</Stack>
		</Box>
	);
};
