import gql from 'graphql-tag';

// GetGlobalContentIdQuery needs to be called first before ImportContentMediaTokenQuery
export const GetGlobalContentIdQuery = gql`
	query GetGlobalContentIdQuery {
		getGlobalDescription {
			id
		}
	}
`;

export const ImportContentMediaTokenQuery = gql`
	query ImportContentMediaTokenQuery($contentId: ID!) {
		contentMediaSession(contentId: $contentId) {
			token {
				value
			}
			configuration {
				clientId
				fileStoreUrl
			}
		}
	}
`;
