import type { FC } from 'react';
import React from 'react';

import Drawer from '@atlaskit/drawer';

import { ImportNotionContentWorkflow } from './ImportNotionContentWorkflow';
import { ImportFileContentWorkflow } from './ImportFileContentWorkflow';
import { ImportContentWorkflowContextProvider } from './ImportContentStore';

export enum ContentType {
	NOTION = 'NOTION',
	HTML = 'HTML',
}

type ImportContentDrawerProps = {
	isImportingContent: boolean;
	setIsImportingContent: (value: boolean) => void;
	contentType: ContentType;
};

export const ImportContentDrawer: FC<ImportContentDrawerProps> = ({
	isImportingContent,
	setIsImportingContent,
	contentType,
}) => {
	const handleOnClose = (event: React.SyntheticEvent<Element, Event>) => {
		const keyboardEvent = event as unknown as React.KeyboardEvent;
		if (keyboardEvent.key === 'Escape' || keyboardEvent.keyCode === 27) {
			keyboardEvent.stopPropagation();
			return;
		}
		setIsImportingContent(false);
	};

	return (
		<Drawer
			width="full"
			// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
			overrides={{
				Sidebar: { component: () => null },
			}}
			isOpen={isImportingContent}
			onClose={(e) => handleOnClose(e)}
		>
			<ImportContentWorkflowContextProvider>
				{contentType === ContentType.NOTION && (
					<ImportNotionContentWorkflow closeDrawer={() => setIsImportingContent(false)} />
				)}
				{contentType === ContentType.HTML && (
					<ImportFileContentWorkflow closeDrawer={() => setIsImportingContent(false)} />
				)}
			</ImportContentWorkflowContextProvider>
		</Drawer>
	);
};
