import React from 'react';
import type { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { ContentStep } from '../ImportFileContentSteps/ContentStep';
import { SpaceStep } from '../ImportFileContentSteps/SpaceStep';
import { StartImportStep } from '../ImportFileContentSteps/StartImportStep';

import { ContentType } from './ImportContentDrawer';
import { Workflow } from './Workflow';
import { i18n as notionI18n } from './ImportNotionContentWorkflow';

const i18n = defineMessages({
	space: {
		id: 'import-content.progress-tracker.space',
		defaultMessage: 'Space',
		description:
			'Label of a progress tracker step where users select the space restrictions of the content they wish to import into Confluence',
	},
});

type ImportFileContentWorkflowProps = {
	closeDrawer: () => void;
};

export const ImportFileContentWorkflow: FC<ImportFileContentWorkflowProps> = ({ closeDrawer }) => {
	const { formatMessage } = useIntl();
	return (
		<Workflow
			contentType={ContentType.HTML}
			progressTrackerStages={[
				{
					id: 'content',
					label: formatMessage(notionI18n.content),
					percentageComplete: 0,
					status: 'current',
				},
				{
					id: 'space',
					label: formatMessage(i18n.space),
					percentageComplete: 0,
					status: 'unvisited',
				},
				{
					id: 'import',
					label: formatMessage(notionI18n.import),
					percentageComplete: 0,
					status: 'unvisited',
				},
			]}
			steps={[ContentStep, SpaceStep, StartImportStep]}
			closeDrawer={closeDrawer}
		/>
	);
};
