export function truncate(file: string): string {
	const filename = file.trim();
	const lastDotIndex = filename.lastIndexOf('.');
	const name: string = filename.substring(0, lastDotIndex);
	const extension: string = filename.substring(lastDotIndex + 1);
	let newName: string;
	if (name.length > 33) {
		const firstThirtyThreeChars: string = name.substring(0, 33);
		// Keep the last 3 characters of the original name
		const lastThreeChars: string = name.slice(-3);
		newName = `${firstThirtyThreeChars}...${lastThreeChars}`;
	} else {
		newName = name;
	}
	const newFilename: string = `${newName}.${extension}`;
	return newFilename;
}

export function formatBytes(bytes: number): string {
	if (bytes <= 0) return '0 B';

	const i = Math.floor(Math.log(bytes) / Math.log(1000));
	const value = bytes / Math.pow(1000, i);

	const units = ['B', 'KB', 'MB', 'GB', 'TB'];
	const decimalPlaces = [0, 0, 2, 1, 1];

	return `${value.toFixed(decimalPlaces[i])} ${units[i]}`;
}
