/**
 * @jsxRuntime classic
 * @jsx jsx
 */
/**
 * @jsxFrag
 */
import React, { forwardRef, type RefObject, useCallback } from 'react';

import { cssMap, cx, jsx } from '@compiled/react';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { AvatarContext, type AvatarContextProps } from '@atlaskit/avatar';
import { Anchor, Pressable, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { InteractionConsumer, InteractionSurface } from '../../components/interaction-surface';

import { useLevel } from './expandable-menu-item/expandable-menu-item-context';
import {
	useFlyoutMenuOpen,
	useSetFlyoutMenuOpen,
} from './flyout-menu-item/flyout-menu-item-context';
import { COLLAPSE_ELEM_BEFORE } from './menu-item-signals';
import type { MenuItemCommonProps } from './types';

const defaultAvatarValues: AvatarContextProps = {
	size: 'small',
};

const elemAfterDisplayVar = '--elem-after-display';
const notchColorVar = '--notch-color';

/**
 * ## 🤹 `position:relative`
 *
 * We need `position:relative` on an element that takes
 * up the full size of the interactive element so we
 * can correctly use `position:absolute` to place:
 * 1. the notch for links
 * 2. a child of button / anchor to stretch it out to
 *    increase it's pressable area.
 *
 * ⛔️ We cannot add `position:relative` _only_ on the
 * button / anchor as that will cause sibling elements
 * to be rendered under the button / anchor when setting
 * a background color on the button / anchor.
 *
 * 📖 Note: `position:relative` elements are painted after
 * elements with `position:static` (the default)
 * https://drafts.csswg.org/css-position-4/#painting-order
 *
 * ⛔️ We cannot add `position:relative` to the container
 * element, as then the `:focus` ring styles on the
 * button / anchor can be cut off by the next sibling if it has
 * has a background color set (eg when selected)
 *
 * ✅ Add `position:relative` to all first level descendants
 * of the container element so that we don't impact DOM ordered
 * paint ordering within the item and the button / anchor focus
 * ring can still bleed over siblings
 *
 * 📖 We could use `> * { position: relative; }` on the container,
 * but that would violate our styling standard.
 */
const topLevelSiblingStyles = cssMap({
	root: {
		position: 'relative',
	},
});

/**
 * All slots on the menu item (eg `elemBefore`) are rendered as siblings
 * of our main button / anchor element and they are visually placed on
 * top of the main button / anchor.
 *
 * 📖 This is done so that we don't nest interactive elements in our markup.
 *
 * ✅ This is great when element in the slot is an interactive element
 * as we don't want the main menu item button / anchor to be triggered
 * when interacting with the element in the slot.
 *
 * ⛔️ When the element in the slot is static content (eg an `<Icon>`) it will
 * prevent the main button / anchor (that is visually behind the element in
 * the slot) from being clicked. The element in the slot is a sibling of our
 * main button / anchor (not a child of it) so clicking on the element in the
 * slot will not bubble up to the button / anchor.
 *
 * 🚀 We set `pointer-events:none` on a slot if it does not contain and interactive
 * element so that static content in a slot does not prevent clicking on the main
 * button / anchor.
 */
const onTopOfButtonOrAnchorStyles = cssMap({
	root: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&:not(:has(button,a))': {
			pointerEvents: 'none',
		},
	},
});

const containerStyles = cssMap({
	root: {
		boxSizing: 'border-box',
		display: 'grid',
		gridTemplateColumns: 'minmax(0, auto) 1fr repeat(3, minmax(0, auto))',
		alignItems: 'center',
		height: '32px',
		userSelect: 'none',
		borderRadius: token('border.radius'),
		color: token('color.text.subtle'),
		[notchColorVar]: 'transparent',
		// Applying :hover styles on the container rather than on
		// just the button / anchor so that we will still trigger the
		// :hover styles when over action buttons
		'&:hover': {
			background: token('elevation.surface.hovered'),
			[notchColorVar]: token('color.background.neutral.hovered'),
		},
		// TODO: BLU-3354 This is a workaround solution to control the hover state of the menu item if there's a nested open popup (we are looking for the popup trigger with aria-expanded="true" as the popup itself might be rendered outside the menu item).
		// A better solution would involve wrapping it with a popup context and listening to the popup events through it (and applying the hover style when the popup is open).
		// Most likely it would require making modifications to Popup itself so that it posts the updates to the context.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
		'&:has([aria-expanded="true"][aria-haspopup="true"])': {
			background: token('elevation.surface.pressed'),
			[notchColorVar]: token('color.background.neutral.hovered'),
		},
	},
	removeElemAfter: {
		[elemAfterDisplayVar]: 'none',
	},
	removeElemAfterOnHover: {
		// On hover of the menu item, remove the elemAfter
		[elemAfterDisplayVar]: 'flex',
		'&:hover, &:focus-within': {
			[elemAfterDisplayVar]: 'none',
		},
	},
	selected: {
		background: token('color.background.selected'),
		color: token('color.text.selected'),
		[notchColorVar]: token('color.background.selected.bold'),
		'&:hover': {
			color: token('color.text.selected'),
			background: token('color.background.selected.hovered'),
			[notchColorVar]: token('color.background.selected.bold'),
		},
	},
	pressed: {
		background: token('elevation.surface.pressed'),
		[notchColorVar]: token('color.background.neutral.hovered'),
	},
	hasDescription: {
		/* Standard 32px + another 12px for the description */
		height: '44px',
	},
});

const buttonOrAnchorStyles = cssMap({
	// This button / anchor is positioned to produce the visual appearance of nested
	// buttons whilst the elements are actually siblings in the DOM structure.
	root: {
		display: 'grid',
		// Extend the button to the full width of container
		gridColumn: '1 / -1',
		// Each grid item is placed on the same row and stacks on top of each other.
		gridRow: '1',
		gridTemplateColumns: 'subgrid',
		height: '100%',
		padding: token('space.050'),
		background: 'none',
		borderRadius: token('border.radius'),
		color: token('color.text.subtle'),
		alignItems: 'center',
		textAlign: 'start',
		// :active styles are applied on the button / anchor rather
		// than on the container so that pressing on actions does not
		// trigger the :active styles on the whole element.
		'&:active': {
			background: token('elevation.surface.pressed'),
		},
	},
	selected: {
		color: token('color.text.selected'),
		'&:active': {
			background: token('color.background.selected.pressed'),
		},
	},
});

const extendButtonOrAnchorStyles = cssMap({
	root: {
		position: 'absolute',
		inset: token('space.0'),
	},
});

const notchStyles = cssMap({
	root: {
		position: 'absolute',
		insetBlockStart: '50%',
		insetInlineStart: token('space.0'),
		width: '2px',
		height: '12px',
		transform: 'translateY(-50%)',
		backgroundColor: `var(${notchColorVar})`,
	},
});

const actionStyles = cssMap({
	root: {
		display: 'flex',
		alignItems: 'center',
		gap: token('space.050'),
		gridRow: '1',
		gridColumn: '5',
		paddingInlineEnd: token('space.050'),
	},
});

const actionOnHoverStyles = cssMap({
	root: {
		display: 'flex',
		alignItems: 'center',
		gap: token('space.050'),
		paddingInlineEnd: token('space.050'),
	},
	gridPosition: {
		gridRow: '1',
		gridColumn: '3',
	},
});

const textStyles = cssMap({
	root: {
		gridColumn: '2',
		paddingInlineEnd: token('space.050'),
		paddingInlineStart: token('space.050'),
	},
	noElemBeforeIndent: {
		paddingInlineStart: token('space.075'),
	},
});

const elemBeforeStyles = cssMap({
	root: {
		gridColumn: '1',
		gridRow: '1',
		display: 'flex',
		flexShrink: 0,
		width: '24px',
		height: '24px',
		alignItems: 'center',
		justifyContent: 'center',
		paddingInlineStart: token('space.050'),
	},
});

const elemAfterStyles = cssMap({
	root: {
		display: `var(${elemAfterDisplayVar}, flex)`,
		gridColumn: '4',
		gridRow: '1',
		flexShrink: 0,
		height: '24px',
		alignItems: 'center',
		paddingInlineEnd: token('space.050'),
	},
});

type MenuItemBaseProps = MenuItemCommonProps & {
	href?: string | Record<string, any>;
	target?: HTMLAnchorElement['target'];
	isDisabled?: boolean;
	isSelected?: boolean;
	// eslint-disable-next-line @repo/internal/react/boolean-prop-naming-convention, @repo/internal/react/consistent-props-definitions
	ariaControls?: string;
	// eslint-disable-next-line @repo/internal/react/boolean-prop-naming-convention, @repo/internal/react/consistent-props-definitions
	ariaExpanded?: boolean;
	// eslint-disable-next-line @repo/internal/react/boolean-prop-naming-convention, @repo/internal/react/consistent-props-definitions
	ariaHasPopup?: boolean | 'dialog';
};

/**
 * __MenuItemBase__
 *
 * The base menu item component used to compose MenuButtonItem and MenuLinkItem.
 */
export const MenuItemBase = forwardRef<HTMLAnchorElement | HTMLButtonElement, MenuItemBaseProps>(
	(
		{
			testId,
			actions,
			actionsOnHover,
			children,
			description,
			elemAfter,
			elemBefore,
			href,
			target,
			isDisabled,
			isSelected,
			onClick,
			ariaControls,
			ariaExpanded,
			ariaHasPopup,
			interactionName,
		},
		forwardedRef,
	) => {
		const level = useLevel();
		const setFlyoutMenuOpen = useSetFlyoutMenuOpen();
		const isFlyoutMenuOpen = useFlyoutMenuOpen();
		const isLink = typeof href !== 'undefined';

		const handleClick = useCallback(
			(
				event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
				analyticsEvent: UIAnalyticsEvent,
			) => {
				onClick?.(event, analyticsEvent);
				// Toggle flyout menu open state when inside a flyout context provider
				setFlyoutMenuOpen(!isFlyoutMenuOpen);
			},
			[onClick, setFlyoutMenuOpen, isFlyoutMenuOpen],
		);

		const buttonAndAnchorSharedProps = {
			'aria-controls': ariaControls,
			'aria-haspopup': ariaHasPopup,
			onClick: handleClick,
		};

		// By default provide the spacing for `elemBefore` to have good
		// vertical alignment of labels and to give clear indentation between levels
		// in the side navigation (even when items don't use elemBefore).
		const showElemBefore = elemBefore !== COLLAPSE_ELEM_BEFORE;

		const interactiveElemContent = (
			<>
				<div
					css={extendButtonOrAnchorStyles.root}
					// This extends the clickable area of nested menu items to the width
					// of the root level menu items, while being visually indented.
					style={{ insetInlineStart: `-${level * 26}px` }}
					aria-hidden="true"
				/>
				<div css={[textStyles.root, !showElemBefore && textStyles.noElemBeforeIndent]}>
					<Text
						weight="medium"
						maxLines={1}
						color={isSelected ? 'color.text.selected' : 'color.text.subtle'}
					>
						{children}
					</Text>
					{description && (
						<Text color="color.text.subtlest" size="small" maxLines={1}>
							{description}
						</Text>
					)}
				</div>
			</>
		);

		// If the menu item is expanded, show hover actions even when not hovered
		const showHoverActionsWhenNotHovered = Boolean(!ariaHasPopup && ariaExpanded && actionsOnHover);

		return (
			<InteractionSurface>
				<AvatarContext.Provider value={defaultAvatarValues}>
					<div
						css={[
							containerStyles.root,
							isSelected && containerStyles.selected,
							description && containerStyles.hasDescription,
							ariaHasPopup && ariaExpanded && containerStyles.pressed,
							// If the menu item has actionsOnHover, remove the elemAfter on hover
							actionsOnHover && elemAfter && containerStyles.removeElemAfterOnHover,
							// If the menu item is expanded, show hover actions even when not hovered
							showHoverActionsWhenNotHovered && containerStyles.removeElemAfter,
						]}
						data-testid={testId ? `${testId}-container` : undefined}
						data-selected={isSelected}
					>
						{isLink ? (
							<Anchor
								{...buttonAndAnchorSharedProps}
								xcss={cx(
									buttonOrAnchorStyles.root,
									topLevelSiblingStyles.root,
									isSelected && buttonOrAnchorStyles.selected,
								)}
								// Needed to override Anchor style due to a compiled/emotion conflict
								// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
								style={{ textDecoration: 'none' }}
								aria-current={isSelected ? 'page' : undefined}
								href={href}
								target={target}
								ref={forwardedRef as RefObject<HTMLAnchorElement>}
								testId={testId}
								interactionName={interactionName}
							>
								<div css={notchStyles.root} aria-hidden="true" />
								{interactiveElemContent}
							</Anchor>
						) : (
							<Pressable
								{...buttonAndAnchorSharedProps}
								xcss={cx(
									buttonOrAnchorStyles.root,
									topLevelSiblingStyles.root,
									isSelected && buttonOrAnchorStyles.selected,
								)}
								aria-expanded={ariaExpanded}
								isDisabled={isDisabled}
								ref={forwardedRef as RefObject<HTMLButtonElement>}
								testId={testId}
								interactionName={interactionName}
							>
								{interactiveElemContent}
							</Pressable>
						)}
						{showElemBefore && (
							<div
								css={[
									elemBeforeStyles.root,
									topLevelSiblingStyles.root,
									onTopOfButtonOrAnchorStyles.root,
								]}
							>
								{elemBefore}
							</div>
						)}
						{actionsOnHover && (
							<InteractionConsumer
								xcss={actionOnHoverStyles.gridPosition}
								// If the menu item is expanded, show hover actions even when not hovered
								behavior={showHoverActionsWhenNotHovered ? 'alwaysShown' : 'removeOnLeave'}
							>
								<div css={actionOnHoverStyles.root}>{actionsOnHover}</div>
							</InteractionConsumer>
						)}
						{elemAfter && (
							<div
								css={[
									elemAfterStyles.root,
									topLevelSiblingStyles.root,
									onTopOfButtonOrAnchorStyles.root,
								]}
							>
								{elemAfter}
							</div>
						)}
						{actions && (
							<div
								css={[
									actionStyles.root,
									topLevelSiblingStyles.root,
									onTopOfButtonOrAnchorStyles.root,
								]}
							>
								{actions}
							</div>
						)}
					</div>
				</AvatarContext.Provider>
			</InteractionSurface>
		);
	},
);
