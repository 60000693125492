import type { FC } from 'react';
import React from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { LoadableLazy } from '@confluence/loadable';

import { useImportContentStore } from './ImportContentStore';
import { ContentType } from './ImportContentDrawer';

const ImportContentModalLoader = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ImportContentModal" */ './ImportContentModal'))
			.ImportContentModal,
});

export const ConditionalImportContentModal: FC = () => {
	const [importContentState, importContentActions] = useImportContentStore();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return importContentState.shouldRenderModal ? (
		<ImportContentModalLoader
			shouldRender={importContentState.showImportContentModal}
			onClose={() => importContentActions.setShowImportContentModal(false)}
			onNotionClick={() => {
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'notionCard',
						source: 'importContentModal',
					},
				}).fire();
				importContentActions.setShowImportContentModal(false);
				importContentActions.setContentType(ContentType.NOTION);
				importContentActions.setShouldRenderDrawer(true);
				importContentActions.setShowImportContentDrawer(true);
			}}
			onFileImportClick={() => {
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'fileImportCard',
						source: 'importContentModal',
					},
				}).fire();
				importContentActions.setShowImportContentModal(false);
				importContentActions.setContentType(ContentType.HTML);
				importContentActions.setShouldRenderDrawer(true);
				importContentActions.setShowImportContentDrawer(true);
			}}
		/>
	) : null;
};
