/* eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values */
/* eslint-disable @atlaskit/design-system/consistent-css-prop-usage */
import React, { useContext } from 'react';
import type { FC } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';
import { Box, Stack, xcss, Inline, Text } from '@atlaskit/primitives';
import { Radio } from '@atlaskit/radio';
import LockIcon from '@atlaskit/icon/glyph/lock';
import UnlockIcon from '@atlaskit/icon/glyph/unlock';

import { PermissionOptions } from '../PermissionOptions';

import { NotionSvg } from './NotionSvg';
import { ImportContentWorkflowContext } from './ImportContentStore';

const i18n = defineMessages({
	title: {
		id: 'import-content.permissions-selector.title',
		defaultMessage: 'Permissions',
		description:
			'Label of a dropdown where the user selects from multiple permissions options for their imported content into Confluence',
	},
	restricted: {
		id: 'import-content.permissions-selector.restricted',
		defaultMessage: 'Restricted to you',
		description:
			'Option in a dropdown for selecting permissions of imported content into Confluence that makes the content restricted to the user',
	},
	default: {
		id: 'import-content.permissions-selector.default',
		defaultMessage: 'Default space permissions',
		description:
			'Option in a dropdown for selecting permissions of imported content into Confluence that makes the content follow default permissions',
	},
	notion: {
		id: 'import-content.permissions-selector.notion',
		defaultMessage: 'Notion permissions',
		description:
			'Option in a dropdown for selecting permissions of imported Notion content into Confluence that makes the content map to their Notion permissions',
	},
	betaLozenge: {
		id: 'import-content.permissions-selector.betaLozenge',
		defaultMessage: 'Beta',
		description: 'Lozenge stating that the option in the dropdown is in beta',
	},
	restrictedSubtext: {
		id: 'import-content.permissions-selector.restricted-subtext',
		defaultMessage: 'Only you can view spaces',
		description:
			'Subtext providing a short description of the restricted option of the permissions dropdown for users configuring their data to import into Confluence',
	},
	defaultSubtext: {
		id: 'import-content.permissions-selector.default-subtext',
		defaultMessage: 'Use default permissions set in Confluence',
		description:
			'Subtext providing a short description of the default option of the permissions dropdown for users configuring their data to import into Confluence',
	},
	notionSubtext: {
		id: 'import-content.permissions-selector.notion-subtext',
		defaultMessage: 'Copy teamspace or parent page permissions from Notion',
		description:
			'Subtext providing a short description of the Notion option of the permissions dropdown for users configuring their data to import into Confluence',
	},
	restrictedExplanation: {
		id: 'import-content.permissions-selector.restricted-explanation',
		defaultMessage:
			'Restricting the space allows you to check the imported content before other users can see it. You will be able to change space permissions later.',
		description:
			'Text below the selected option of a permissions dropdown explaining how the restricted option works',
	},
	defaultExplanation: {
		id: 'import-content.permissions-selector.default-explanation',
		defaultMessage: 'Allows group access as defined by Confluence defaults.',
		description:
			'Text below the selected option of a permissions dropdown explaining how the default option works',
	},
	notionExplanation: {
		id: 'import-content.permissions-selector.notion-explanation',
		defaultMessage:
			'Imported space will be immediately available to users who had access to it in Notion.',
		description:
			'Text below the selected option of a permissions dropdown explaining how the Notion option works',
	},
	sectionHeading: {
		id: 'import-content.permissions-selector.sectionHeading',
		defaultMessage: 'Space permissions',
		description: 'Title of the section where space permissions are selected',
	},
});

const PermissionsIcon: FC<{ permissionType: PermissionOptions }> = ({ permissionType }) => {
	switch (permissionType) {
		case PermissionOptions.RESTRICTED:
			return (
				<LockIcon label="restricted" size="small" primaryColor={token('color.icon.accent.red')} />
			);
		case PermissionOptions.NOTION:
			return (
				<Box paddingInline="space.050" paddingBlockStart="space.050">
					<NotionSvg size="small" />
				</Box>
			);
		case PermissionOptions.DEFAULT:
			return (
				<UnlockIcon label="default" size="small" primaryColor={token('color.icon.accent.gray')} />
			);
	}
};

type PermissionCardProps = {
	isDisabled?: boolean;
	permissionType: PermissionOptions;
	selectedPermissions: PermissionOptions;
	setSelectedPermissions: (value: PermissionOptions) => void;
};

const PermissionCard: FC<PermissionCardProps> = ({
	isDisabled = false,
	permissionType,
	selectedPermissions,
	setSelectedPermissions,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const titles = {
		[PermissionOptions.DEFAULT]: i18n.default,
		[PermissionOptions.NOTION]: i18n.notion,
		[PermissionOptions.RESTRICTED]: i18n.restricted,
	};

	const subtexts = {
		[PermissionOptions.DEFAULT]: i18n.defaultExplanation,
		[PermissionOptions.NOTION]: i18n.notionExplanation,
		[PermissionOptions.RESTRICTED]: i18n.restrictedExplanation,
	};

	const isSelected = permissionType === selectedPermissions;

	const boxSelectionStyle =
		isSelected && !isDisabled
			? xcss({
					backgroundColor: 'color.background.selected',
					borderColor: 'color.border.selected',
				})
			: xcss({
					backgroundColor: 'elevation.surface',
					borderColor: 'color.border',
				});

	return (
		<div data-testid={`permission-card-${permissionType}`}>
			<Box
				xcss={[
					xcss({
						border: `1px solid ${token('color.border')}`,
						padding: 'space.200',
						borderRadius: '5px',
					}),
					boxSelectionStyle,
				]}
				onClick={() => {
					createAnalyticsEvent({
						type: 'sendUIEvent',
						data: {
							action: 'select',
							actionSubject: 'permissionsDropdown',
							source: 'importContentSetUpSpaces',
							attributes: {
								permissionType,
								contentType: 'Notion',
							},
						},
					}).fire();
					setSelectedPermissions(permissionType);
				}}
			>
				<Inline>
					<Radio value={permissionType} isChecked={isSelected} isDisabled={isDisabled} />
					<Stack
						xcss={xcss({
							paddingLeft: 'space.100',
						})}
					>
						<Inline
							alignBlock="center"
							xcss={xcss({
								marginBottom: 'space.100',
							})}
						>
							<Box
								xcss={xcss({
									marginRight: 'space.100',
								})}
							>
								<PermissionsIcon permissionType={permissionType} />
							</Box>
							<b role="heading" aria-level={3}>
								<FormattedMessage {...titles[permissionType]} />
							</b>
						</Inline>
						<Text size="small" color="color.text.subtlest">
							<FormattedMessage {...subtexts[permissionType]} />
						</Text>
					</Stack>
				</Inline>
			</Box>
		</div>
	);
};

export const PermissionsSelector: FC = () => {
	const context = useContext(ImportContentWorkflowContext);

	const { selectedPermissions, setSelectedPermissions, shouldCopyPagePermissions } = context;

	if (shouldCopyPagePermissions) {
		setSelectedPermissions(PermissionOptions.NOTION);
	}

	return (
		<Stack space="space.100">
			<Heading as="h2" size="xxsmall">
				<FormattedMessage {...i18n.sectionHeading} />
			</Heading>
			{shouldCopyPagePermissions ? (
				<PermissionCard
					isDisabled
					permissionType={PermissionOptions.NOTION}
					selectedPermissions={selectedPermissions}
					setSelectedPermissions={setSelectedPermissions}
				/>
			) : (
				<>
					<PermissionCard
						permissionType={PermissionOptions.DEFAULT}
						selectedPermissions={selectedPermissions}
						setSelectedPermissions={setSelectedPermissions}
					/>
					<PermissionCard
						permissionType={PermissionOptions.RESTRICTED}
						selectedPermissions={selectedPermissions}
						setSelectedPermissions={setSelectedPermissions}
					/>
				</>
			)}
		</Stack>
	);
};
